import { ModelType } from '@biteinc/enums';

import { getSeverity } from './bite_log_helper';

app.BiteLog = app.AbstractModel.extend({
  ModelName: 'biteLog',
  Schema: {
    displayName: 'system log',
  },
  Type: ModelType.BiteLog,

  matchesQuery(query /* optDisplayName */) {
    if (!query) {
      return true;
    }

    const lcQuery = query.toLowerCase();

    const description = this.get('description');
    const descriptionMatchesQuery = description?.toLowerCase().indexOf(lcQuery) !== -1;
    if (descriptionMatchesQuery) {
      return true;
    }

    const humanReadableType = this.get('humanReadableType');
    const humanReadableTypeMatchesQuery = humanReadableType?.toLowerCase().indexOf(lcQuery) !== -1;
    if (humanReadableTypeMatchesQuery) {
      return true;
    }

    const humanReadableStatus = this.get('humanReadableStatus');
    const humanReadableStatusMatchesQuery =
      humanReadableStatus?.toLowerCase().indexOf(lcQuery) !== -1;
    if (humanReadableStatusMatchesQuery) {
      return true;
    }

    return app.AbstractModel.prototype.matchesQuery.apply(this, arguments);
  },

  isSimilar(other) {
    return (
      this.get('clientName') === other.get('clientName') &&
      this.get('type') === other.get('type') &&
      this.get('status') === other.get('status') &&
      !this.get('isFinalError')
    );
  },

  isKioskOk() {
    return getSeverity(this.get('type'), this.get('status')) === 'recovery';
  },

  isError() {
    if (this.get('isFinalError')) {
      return true;
    }

    return getSeverity(this.get('type'), this.get('status')) === 'error';
  },

  isWarning() {
    return getSeverity(this.get('type'), this.get('status')) === 'warning';
  },

  displayNameHtml() {
    return this.get('description').split('\n').join('<br />');
  },
});
