import async from 'async';
import Backbone from 'backbone';
import qs from 'qs';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { ModelType, ModelTypeHelper } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';

import { template } from '../../template';

app.BaseDetailsView = Backbone.View.extend({
  valueDisplayTemplate: template($('#value-display-template').html()),
  _viewHasBeenAddedToDom: false,

  initialize(options) {
    this.options = _.extend(
      {
        canAccessParentModel: true,
      },
      options || {},
    );

    this.isReadOnly = this.options.isReadOnly;
    this.canAccessParentModel = this.options.canAccessParentModel;

    this.collection = this.options.collection;
    // In case this collection is actually a DataSource
    if (this.collection && this.collection.getMainCollection) {
      this.collection = this.collection.getMainCollection();
    }

    this.model = this.options.model;
    if (!this.model) {
      const attributes = this.options.attributes || {};
      // eslint-disable-next-line new-cap
      this.model = new this.collection.model(attributes, {
        collection: this.collection,
      });
    }
    this.isReadOnly = this.isReadOnly || !this.model.canBeManaged();

    if (!this.collection) {
      this.collection = this.model.collection;
    }

    let evt = 'change';
    if (this.options.subProperty) {
      evt += `:${this.options.subProperty}`;
    }
    this.listenTo(this.model, evt, (e) => {
      if (this.$el.html().length) {
        this.modelDidChange(e);
      }
    });
  },

  modelDidChange(/* model */) {
    this.render();
  },

  _hasUnsavedChanges() {
    if (this.model.isNew()) {
      return true;
    }

    return _.any(this.fieldGroupView.getValue(), (value, key) => {
      let modelValue = this.model.get(key);
      if (this.options.subProperty) {
        const subProperty = this.model.get(this.options.subProperty);
        if (subProperty && _.has(subProperty, key)) {
          modelValue = subProperty[key];
        } else {
          modelValue = null;
        }
      }
      const isDifferent = !_.isEqual(modelValue, value);
      if (isDifferent) {
        Log.debug('diff', key, 'model:', modelValue, 'val:', value, ']');
      }
      return isDifferent;
    });
  },

  checkForUnsavedChanges() {
    if (this.isDestroyed) {
      return false;
    }

    let hasUnsavedChanges = this._hasUnsavedChanges();

    const footer = this.superView.footer;
    const isShowingWarning = footer.getState() === footer.states.DISCARD;
    if (hasUnsavedChanges && !isShowingWarning) {
      footer.showDiscardConfirmation();
    } else if (isShowingWarning) {
      hasUnsavedChanges = false;
    }

    return hasUnsavedChanges;
  },

  getDeleteConfirmation() {
    return `Are you sure you want to delete this ${this.getSchema().displayName}?`;
  },

  getFocusFieldName() {
    return 'name';
  },

  getSchema() {
    return this.options.schema || this.collection.getSchema();
  },

  getTitle() {
    if (this.options.title) {
      return this.options.title;
    }
    let title = '';
    if (this.isReadOnly) {
      title += 'View';
    } else if (this.model.isNew()) {
      title += 'Create a New';
    } else {
      title += 'Edit';
    }
    title += ' ';
    title += StringHelper.toTitleCase(this.getSchema().displayName);
    return title;
  },

  canShowDeleteButton() {
    return !this.options.isReadOnly && !this.options.subProperty && this.model.canBeDestroyed();
  },

  flashFieldView(id) {
    const el = $(`#${id}`);
    const animation = 'flash';
    const animationEnd =
      'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    el.toggleClass('animated', true);
    el.toggleClass(animation, true);
    el.one(animationEnd, () => {
      el.toggleClass('animated', false);
      el.toggleClass(animation, false);
      history.pushState({}, '', `${location.origin}${location.pathname}${location.hash}`);
    });
  },

  viewWasAddedToDom() {
    this._viewHasBeenAddedToDom = true;

    $(document).on('keydown.baseDetailsView', (e) => {
      if (e.ctrlKey && app.KEYCODE.ENTER === e.keyCode) {
        this.superView.footer.simulateSaveButtonClick();
        return false;
      }
      return true;
    });

    if (!this.fieldGroupView) {
      return;
    }

    const fieldName = this.getFocusFieldName();
    if (fieldName && this.model.isNew()) {
      const fieldView = this.fieldGroupView.fieldViewsByField[fieldName];
      if (fieldView) {
        fieldView.$form.focus();
      }
    }
    this.fieldGroupView.viewWasAddedToDom();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (query.fieldKey) {
      const el = document.getElementById(query.fieldKey);
      if (el) {
        el.scrollIntoView();
        setTimeout(() => {
          this.flashFieldView(query.fieldKey);
        }, 400);
      } else {
        history.pushState({}, '', `${location.origin}${location.pathname}${location.hash}`);
      }
    }
  },

  viewWasRemovedFromDom() {
    $(document).unbind('keydown.baseDetailsView');
    this.trigger(app.BaseDetailsView.Events.BaseDetailsViewDidClose, this);
  },

  setSuperView(superView) {
    this.superView = superView;
  },

  clearState() {
    this.stopListening();
    this.fieldGroupView.destroy();
    this.fieldGroupView = null;
    this.callback = null;
    this.superView = null;
  },

  // Entry points for subclasses to inject custom behavior at those key
  // points in the model lifecycle. Just override and do what you need.
  _didDestroyModel() {},
  _didSaveModel() {},

  __fieldGroupDidChangeValue() {
    this._updateFooterState();
  },

  _updateFooterState() {
    if (this.isDestroyed) {
      return;
    }
    this.superView.footer.setSaveButtonEnabled(this._hasUnsavedChanges());
  },

  __saveButtonWasClicked($saveButton) {
    const { isValid, invalidFieldNames } = this.fieldGroupView.checkValidity();
    if (!isValid) {
      const actionName = this._saveButtonTitles()[1].toLowerCase();
      const modelName = this.getSchema().displayName;

      new app.AlertView().show(
        `There were some errors with the data you entered. Please fix them first before ${actionName} this ${modelName}`,
        `<br />
        <div>The following fields need some help:
          <ul>${invalidFieldNames.map((fieldName) => `<li>${fieldName}</li>`).join('')}</ul>
        </div>`,
      );
      return;
    }

    const params = this.fieldGroupView.getValue();
    let data = {};
    const opts = {};
    if (this.options.subProperty) {
      data[this.options.subProperty] = params;
      opts.patch = true;
    } else {
      data = params;
    }
    this._save(data, opts, $saveButton);
  },

  _save(data, opts, $saveButton) {
    // Only check for duplicate magic copy key if we have a location
    if (app.location) {
      // If we do not have a pos i9n schema we need to make sure
      // that this objects's name + internal does not exactly match with another object in the collection
      let verifyDuplicateMagicCopyKey = !app.location.getPosI9nSchema();

      if (!verifyDuplicateMagicCopyKey) {
        switch (this.model.Type) {
          case ModelType.MenuSection:
            const posI9n = app.location.getFullPosI9n();
            verifyDuplicateMagicCopyKey = !posI9n.get('syncSections');
        }
      }

      // If we don't have a collection we have nothing to compare against
      if (this.model.collection && verifyDuplicateMagicCopyKey) {
        const saveObjectName = data.name || this.model.get('name');

        // internalName could be set to '' which is falsy
        let saveObjectInternalName = data.internalName ?? this.model.get('internalName');
        const displayName = this.getSchema().displayName;
        const futureMagicCopyKey = StringHelper.slugArray(saveObjectName, saveObjectInternalName);

        const exactMatch = this.model.collection.models.find((obj) => {
          return (
            obj.get('magicCopyKey') === futureMagicCopyKey &&
            (!this.model.id || obj.id !== this.model.id) && // don't look for yourself
            (this.model.isMod === undefined || this.model.isMod() === obj.get('isMod')) // Make sure mods and items do not look at each other
          );
        });

        if (exactMatch) {
          const warning = `Oops! A ${displayName} with the same name + internal name already exists.`;
          const confirmationText = 'Please make the name + internal name unique.';

          app.AlertView.showModelList([exactMatch], warning, confirmationText);
          return;
        }
      }
    }

    const self = this;
    const reqId = $saveButton.initLoadingButton(
      $saveButton.html(),
      this._saveButtonTitles()[1],
      this._saveButtonTitles()[2],
    );

    const callback = this.options.callback;

    opts.error = () => {
      $saveButton.loadingDidFinishWithError(reqId);
    };
    opts.success = (_model, response) => {
      $saveButton.loadingDidFinishSuccessfully(reqId);
      if (self.collection && !self.collection.get(self.model.id)) {
        self.collection.add(self.model);
      }
      self._didSaveModel(response);
      setTimeout(() => {
        if (this.superView && !this.superView.isAnchored && this.options.dismissOnSave) {
          this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
        }
        if (callback) {
          callback(this.model);
        }
      }, 1500);
    };

    this.model.save(data, opts);
  },

  _isNew() {
    if (this.options.subProperty) {
      return !this.model.has(this.options.subProperty);
    }
    return this.model.isNew();
  },

  _saveButtonTitles() {
    if (this.options.saveButtonTitles) {
      return this.options.saveButtonTitles;
    }
    if (this._isNew()) {
      return ['Create', 'Creating', 'Creating'];
    }
    return ['Update', 'Updating', 'Updated'];
  },

  _copySettingsButtonWasClicked($button) {
    const modelSchema = this.getSchema();
    const sourceModel = this.model;

    // Remove the source model from the collection since it doesn't make any sense to copy to itself
    const collection = this.collection.clone();
    collection.remove(sourceModel);

    async.auto(
      {
        modelIds: (cb) => {
          const modelType = StringHelper.toTitleCase(modelSchema.displayName);
          const maxSelectable = null;
          const modelListView = new app.ModelSelectorListView({ collection, maxSelectable });
          app.showModelSelector(
            {
              modalTitle: `Select destination ${StringHelper.pluralize(
                modelType,
              ).toLowerCase()} to copy to (Step 1/2)`,
              modelType,
              modelListView,
              maxSelectable,
            },
            cb,
          );
        },
        options: [
          'modelIds',
          (results, cb) => {
            const copyableFieldNames = sourceModel.fieldNamesThatCanBeCopiedToOtherModels();
            const detailsView = new app.SchemaDetailsView({
              title: 'Select attributes to copy over (Step 2/2)',
              saveButtonTitles: [' ✨Copy Settings ✨', ' ✨Copying ✨', ' ✨Done! ✨'],
              subProperty: 'options',
              schema: {
                // Create checkboxes for each value we can copy
                fields: copyableFieldNames.reduce((allFields, fieldName) => {
                  const tooltip = sourceModel.getCopyableFieldTooltip(fieldName);

                  return {
                    ...allFields,
                    ...(sourceModel.has(fieldName) && {
                      [fieldName]: {
                        type: 'bool',
                        ...(sourceModel.shouldSpecificFieldBeCopiedByDefault(fieldName) && {
                          defaultValue: true,
                        }),
                        displayName:
                          modelSchema.fields[fieldName].displayName ||
                          StringHelper.toTitleCase(fieldName),
                        ...(tooltip && { tooltip }),
                      },
                    }),
                  };
                }, {}),
              },
              callback(settingsModel) {
                cb(null, settingsModel.options);
              },
            });
            app.modalManager.showModalWithView(detailsView);
          },
        ],
      },
      (err, results) => {
        const fieldNames = Object.entries(results.options).reduce((fieldNames, [key, value]) => {
          if (value) {
            return [...fieldNames, key];
          }
          return fieldNames;
        }, []);

        if (!fieldNames.length) {
          new app.AlertView().show('Please select at least one attribute to copy.');
          return;
        }

        const reqId = $button.initLoadingButton($button.html(), 'Copying', 'Copied');
        app.makeRequestWithOptions({
          method: 'PUT',
          url: `${collection.url()}/intra-location-copy`,
          data: sourceModel.getIntraLocationCopyPayload(fieldNames, results.modelIds),
          onSuccess: (data) => {
            const errors = [];
            data.results.forEach((result) => {
              if (result.success) {
                const model = collection.get(result.model._id);
                model.set(result.model);
              } else {
                const model = collection.get(result.modelId);
                errors.push(`${model.displayName()}: ${result.error}`);
              }
            });

            const successCount = data.results.filter(({ success }) => !!success).length;
            new app.AlertView().showWarnings(
              errors,
              `Copy Results (${successCount}/${data.results.length})`,
            );

            $button.loadingDidFinishSuccessfully(reqId);
          },
          onError: (_err) => {
            $button.loadingDidFinishWithError(reqId);
          },
        });
      },
    );
  },

  _precisionCopyButtonWasClicked($button) {
    async.auto(
      {
        locationIds: (cb) => {
          app.showLocationSelector(
            {
              modalTitle: 'Select destination locations to copy to',
              locationsUrl: '/api/v2/magic-copy/locations',
              locationGroupsUrl: '/api/v2/magic-copy/location-groups',
              maxSelectable: null,
            },
            cb,
          );
        },
      },
      (err, results) => {
        const reqId = $button.initLoadingButton($button.html(), 'Copying', 'Copied');
        const url = `/api/v2/magic-copy/precision/${this.model.id}`;
        app.QueueHelper.makeRequest(
          'POST',
          url,
          {
            toLocationIds: results.locationIds,
            type: this.model.Type,
          },
          true,
          (err) => {
            if (err) {
              $button.loadingDidFinishWithError(reqId);
            } else {
              $button.loadingDidFinishSuccessfully(reqId);
            }
          },
        );
      },
    );
  },

  _createVariationWasClicked($button) {
    const detailsView = new app.SchemaDetailsView({
      title: 'Create a Variation',
      schema: {
        fields: {
          internalName: {
            type: 'shortString',
            displayName: 'Internal Name',
            required: true,
          },
          i9nIdSuffix: {
            type: 'keyString',
            displayName: 'IntegrationID Variation',
            required: true,
          },
        },
      },
      callback: (variationBody) => {
        const reqId = $button.initLoadingButton($button.html(), 'Creating', 'Created');
        app.makeRequestWithOptions({
          method: 'POST',
          url: `${this.collection.url()}/${this.model.id}/variation`,
          data: variationBody,
          onSuccess: (data) => {
            const ModelClass = this.collection.model;
            const newModel = new ModelClass(data[ModelClass.prototype.ModelName]);
            this.collection.add(newModel);

            $button.loadingDidFinishSuccessfully(reqId);
          },
          onError: (_err) => {
            $button.loadingDidFinishWithError(reqId);
          },
        });
      },
    });
    app.modalManager.showModalWithView(detailsView);
  },

  render() {
    if (this.superView.$titleLabel) {
      this.superView.$titleLabel.text(this.getTitle());
    }

    if (this.fieldGroupView) {
      this.fieldGroupView.destroy();
      this.stopListening(this.fieldGroupView);
    }
    this.fieldGroupView = new app.FieldGroupView({
      schema: this.getSchema(),
      subProperty: this.options.subProperty,
      isReadOnly: this.isReadOnly,
      model: this.model,
    });
    this.listenTo(
      this.fieldGroupView,
      app.FieldGroupView.Events.FieldGroupDidChangeValue,
      this.__fieldGroupDidChangeValue,
    );
    this.$el.html(this.fieldGroupView.render().el);
    let value = {};
    if (this.tempValue) {
      value = this.tempValue;
      this.tempValue = null;
    } else if (this.options.subProperty) {
      value = this.model.get(this.options.subProperty) || {};
    } else {
      value = this.model.attributes;
    }
    this.fieldGroupView.setValue(value, this.model);

    const footer = this.superView.footer;
    if (this.canShowDeleteButton()) {
      const deleteConfirmation = this.getDeleteConfirmation();
      const deleteCallback = ($deleteButton) => {
        const reqId = $deleteButton.initLoadingButton('Delete', 'Deleting');
        this.model.destroy({
          error: () => {
            $deleteButton.loadingDidFinishWithError(reqId);
          },
          success: () => {
            this._didDestroyModel();
            this.isDestroyed = true;
            $deleteButton.loadingDidFinishSuccessfully(reqId);
            this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
          },
        });
      };
      footer.setDeleteButtonVisible(true, deleteConfirmation, deleteCallback);
    } else {
      footer.setDeleteButtonVisible(false);
    }

    if (!this._$copySettingsToButton && !this.isReadOnly) {
      const canCopyWithinLocation = this.model.fieldNamesThatCanBeCopiedToOtherModels().length;
      const canCopyToOtherLocation = this.model.canBePrecisionCopied();

      if (canCopyWithinLocation && canCopyToOtherLocation) {
        const modelName = StringHelper.toTitleCase(ModelTypeHelper.name(this.model.Type));
        const modelNamePlural = StringHelper.pluralize(modelName);
        this._$copySettingsToButton = footer.addDropdownButton(
          'Copy...',
          {
            'within-location': `Copy settings to different ${modelNamePlural}`,
            'to-location': `Copy settings to different locations`,
            ...(this.model.Type === ModelType.MenuItem &&
              this.model.isSynced() &&
              !this.model.isVariationItem() && {
                'create-variation': `Create a variation`,
              }),
          },
          ($button, className) => {
            switch (className) {
              case 'within-location':
                this._copySettingsButtonWasClicked($button);
                break;
              case 'to-location':
                this._precisionCopyButtonWasClicked($button);
                break;
              case 'create-variation':
                this._createVariationWasClicked($button);
                break;
            }
            return false;
          },
        );
      } else if (canCopyToOtherLocation) {
        this._$copySettingsToButton = footer.addButton('Copy Settings to...', 'copy-settings-to');
        this._$copySettingsToButton?.click(() => {
          this._precisionCopyButtonWasClicked(this._$copySettingsToButton);
          return false;
        });
      } else if (canCopyWithinLocation) {
        this._$copySettingsToButton = footer.addButton('Copy Settings to...', 'copy-settings-to');
        this._$copySettingsToButton?.click(() => {
          this._copySettingsButtonWasClicked(this._$copySettingsToButton);
          return false;
        });
      }
    }

    if (app.sessionUser.isBiteSupport() && !this._$jsonButton && this.model.hasViewableJson()) {
      this._$jsonButton = footer.addButton('View JSON ⚒', 'json');
      this._$jsonButton.click(() => {
        const modelName = this.getSchema().displayName;
        const jsonView = new app.ModelJsonView({
          model: this.model,
          altText: modelName,
          title: `${StringHelper.toTitleCase(modelName)}: ${this.model.displayName()}`,
        });
        app.modalManager.showModalWithView(jsonView);
        return false;
      });
    }

    footer.setSaveButtonVisible(!this.isReadOnly, this._saveButtonTitles()[0], ($saveButton) => {
      this.__saveButtonWasClicked($saveButton);
    });

    setTimeout(() => {
      this._updateFooterState();

      // Perform all this after the subclass has rendered
      app.activateTooltips(this.$el);
      this.$('[model-id]').click((e) => {
        app.trigger(app.Event.ModelIdWasClicked, {
          parentView: this,
          $targetView: $(e.currentTarget),
        });
      });
    }, 1);

    if (this._viewHasBeenAddedToDom) {
      this.fieldGroupView.viewWasAddedToDom();
    }

    return this;
  },
});

app.BaseDetailsView.Events = {
  BaseDetailsViewDidClose: 'BaseDetailsViewDidClose',
};
