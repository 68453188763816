import Backbone from 'backbone';

import { SchemaFieldSearchHelper } from '../helpers/schema_field_search_helper';
import { template } from '../template';

app.BiteSearchView = Backbone.View.extend({
  className: 'bite-search-view',

  template: template(
    // prettier-ignore
    '<div class="search-field">' +
      '<div class="form-group">' +
        '<div class="input-container">' +
          '<input type="search" class="form-control search" placeholder="<@= placeholder @>" />' +
        '</div>' +
      '</div>' +
      '<div class="helper-text">Enter key words for the field you are searching for. E.g. "web" if searching for a web field.</div>' +
    '</div>' +
    '<div class="search-results list-container list-group"></div>',
  ),

  initialize(options) {
    this._options = options || {};
  },

  _getPathFromContext(context) {
    if (context === this._options.context) {
      return location.pathname;
    }
    // We can only get here if we are in the site or channel context
    if (context === 'org') {
      return `/${app.org.get('normalizedName')}`;
    }
    // We can only get here if we are in the channel context
    if (context === 'site') {
      return `/${app.org.get('normalizedName')}/${app.site.get('normalizedName')}`;
    }
  },

  _searchFieldDidUpdate() {
    const searchText = this._$searchField.val().trim();
    if (searchText) {
      const matches = SchemaFieldSearchHelper.getSchemaFieldSections(
        this._options.context,
        searchText,
      );
      this._$searchResults.html('');
      if (matches.length) {
        matches.forEach((match) => {
          const { context, displayName, fieldKey, section } = match;
          const contextString =
            match.context !== this._options.context ? ` (${context} level)` : '';
          const path = this._getPathFromContext(context);
          const $matchLink = $(
            `<a class="match" href="${location.origin}${path}?fieldKey=${fieldKey}#${section}">${displayName}${contextString}</a>`,
          );
          const $matchDiv = $(
            // prettier-ignore
            '<div class="result list-group-item cell clickable">' +
              '<div class="cell-row">' +
              '</div>' +
            '</div>',
          );
          $matchDiv.find('.cell-row').append($matchLink);
          this._$searchResults.append($matchDiv);
          $matchLink.click(() => {
            this._options.onRemove();
            this.remove();
          });
        });
      } else {
        this._$searchResults.html(`<div class="match">No matching properties for search.</div>`);
      }
    } else {
      if (this._matchesView) {
        this._matchesView.remove();
        this._matchesView = null;
      }
    }
  },

  addSearchTool() {
    this.$el.html(
      this.template({
        placeholder: 'Search for a setting to view its Tab',
      }),
    );

    this._$searchField = this.$('.search-field input');
    this._$searchField.on('keyup', this._searchFieldDidUpdate.bind(this));
    this._$searchField.on('search', this._searchFieldDidUpdate.bind(this));
    this._$searchResults = this.$('.search-results');
    this._$searchField.focus();
  },

  render() {
    this.addSearchTool();
    return this;
  },
});
