import async from 'async';
import qs from 'qs';
import _ from 'underscore';

import { TimeHelper } from '../../helpers/time_helper';
import { template } from '../../template';

function releaseHistoryHtml(releaseHistory) {
  return _.chain(releaseHistory)
    .reverse()
    .map((history) => {
      const timestamp = TimeHelper.format(history.releasedAt);
      return `[${timestamp}] ${history.username}: ${history.description}`;
    })
    .value()
    .join('<br />');
}

function getLocationAndName(locationId) {
  const location = app.orgList.getLocationById(locationId);
  if (location) {
    return {
      location,
      locationName: location.debugNameWithChannel(),
    };
  }
  return {
    location: null,
    locationName: `${locationId} (deleted)`,
  };
}

function availabilityHtml(build) {
  if (build.get('rejectedAt')) {
    return '<span class="text-danger">Rejected</span>';
  }
  if (build.get('generalAvailability')) {
    return '<span class="text-success">Generally Available</span>';
  }
  if (build.hasArr('locationIds')) {
    const locationNames = _.chain(build.get('locationIds'))
      .sortBy((locationId) => {
        return getLocationAndName(locationId).locationName.toLowerCase();
      })
      .map((locationId) => {
        const { location, locationName } = getLocationAndName(locationId);
        if (location) {
          const canaryDesignation = location.isCanary() ? '✪ ' : '';
          return `${canaryDesignation}${locationName}`;
        }
        return locationName;
      })
      .value();
    return `Available at: ${locationNames.join(', ')}`;
  }
  return 'Not available anywhere';
}

app.BuildCellView = app.CellView.extend({
  className() {
    return `build-cell-view ${app.CellView.prototype.className.apply(this, arguments)}`;
  },
  template: template(
    // prettier-ignore
    '<div class="name-timestamp">' +
      '<div class="name"><@= name @></div>' +
      '<div class="timestamp"><@= timestamp @></div>' +
    '</div>' +
    '<div class="availability"><@= availability @></div>' +
    '<div class="release-notes-container">' +
      '<div class="toggle">' +
        '<span class="title">Release Notes</span>' +
        '<span class="arrow">▼</span>' +
      '</div>' +
      '<div class="release-notes"><@= releaseNotes @></div>' +
    '</div>' +
    '<div class="release-history-container">' +
      '<div class="toggle">' +
        '<span class="title">Release History</span>' +
        '<span class="arrow">▼</span>' +
      '</div>' +
      '<div class="release-history"><@= releaseHistory @></div>' +
    '</div>' +
    '<div class="button-container">' +
      '<@ if (isBiteOps) { @>' +
        '<button type="button" class="btn btn-sm btn-primary set-locations">Select Locations...⚒</button>' +
      '<@ } @>' +
      '<@ if (isBiteEng) { @>' +
        '<button type="button" class="btn btn-sm btn-success turn-on-ga">General Availability ⚙</button>' +
      '<@ } @>' +
      '<@ if (isBiteOps) { @>' +
        '<button type="button" class="btn btn-sm btn-danger roll-back">Roll Back ⚒</button>' +
        '<button type="button" class="btn btn-sm btn-danger reject-build">Reject Build ⚒</button>' +
      '<@ } @>' +
      '<button type="button" class="btn btn-sm sentry">' +
        '<a href="<@= sentryUrl @>" target="_blank">' +
          '<img src="https://static.bureau.getbite.com/images/icon-sentry.png" />' +
          'View Sentry Issues' +
        '</a>' +
      '</button>' +
    '</div>',
  ),

  _selectLocations($button, preselectedLocationIds) {
    const build = this.model;
    async.auto({
      locationIds: (cb) => {
        const {
          modal: { footer },
          modelListView: locationListView,
        } = app.showLocationSelector(
          {
            modalTitle: `Select locations to release build ${build.displayName()} to:`,
            locationsUrl: `/api/locations?${qs.stringify({ filter: 'builds' })}`,
            maxSelectable: null,
            liveFirst: true,
            allowEmpty: true,
          },
          cb,
        );
        locationListView.selectModelIds(preselectedLocationIds);
        const $canaryLocationsButton = footer.addButton(
          'Select All ✪ Canary Locations',
          'live-test-locations',
          true,
        );
        $canaryLocationsButton.click(() => {
          const canaryLocationIds = locationListView.collection
            .getModels()
            .filter((location) => location.isCanary())
            .map((location) => location.id);
          locationListView.selectModelIds(canaryLocationIds);
        });
      },
      setLocations: [
        'locationIds',
        (results, cb) => {
          const reqId = $button.initLoadingButton($button.html(), 'Releasing', 'Released');
          build.save(
            {
              locationIds: results.locationIds,
            },
            {
              patch: true,
              method: 'PUT',
              url: `/api/v2/builds/${build.id}/locations`,
              error: () => {
                $button.loadingDidFinishWithError(reqId);
              },
              success: () => {
                // Remove the new locations from any existing builds
                const buildList = this.options.collection;
                buildList.models.forEach((otherBuild) => {
                  if (otherBuild.id === build.id || !otherBuild.get('locationIds')?.length) {
                    return;
                  }

                  const newLocationIds = otherBuild.get('locationIds').filter((locationId) => {
                    return !results.locationIds.includes(locationId);
                  });
                  if (newLocationIds.length !== otherBuild.get('locationIds').length) {
                    otherBuild.set('locationIds', newLocationIds);
                  }
                });

                $button.loadingDidFinishSuccessfully(reqId);
                app.showSavedToastAndReload('Build Released!');
                cb();
              },
            },
          );
        },
      ],
    });
  },

  _rejectBuild($button) {
    const rejectReason = prompt(
      'Are you sure you want to reject the build? This cannot be undone! If so, please provide the reason.',
    );

    const build = this.model;
    const reqId = $button.initLoadingButton($button.html(), 'Rejecting', 'Rejected!');

    build.save(
      {
        rejectReason,
      },
      {
        patch: true,
        method: 'PUT',
        url: `/api/v2/builds/${build.id}/reject`,
        error() {
          $button.loadingDidFinishWithError(reqId);
        },
        success() {
          $button.loadingDidFinishSuccessfully(reqId);
          app.showSavedToast('Rejected!');
        },
      },
    );
  },

  _turnOnGeneralAvailability($button) {
    const build = this.model;
    const reqId = $button.initLoadingButton($button.html(), 'Turning ON', 'Turned ON!');

    build.save(
      {},
      {
        patch: true,
        method: 'PUT',
        url: `/api/v2/builds/${build.id}/general-availability`,
        error() {
          $button.loadingDidFinishWithError(reqId);
        },
        success() {
          $button.loadingDidFinishSuccessfully(reqId);
          app.showSavedToast('Build Released!');
        },
      },
    );
  },

  _rollBack($button) {
    const build = this.model;
    const reqId = $button.initLoadingButton($button.html(), 'Rolling back', 'Rolled back!');

    build.save(
      {},
      {
        patch: true,
        method: 'PUT',
        url: `/api/v2/builds/${build.id}/roll-back`,
        error() {
          $button.loadingDidFinishWithError(reqId);
        },
        success() {
          $button.loadingDidFinishSuccessfully(reqId);
          app.showSavedToast('Rolled back!');
        },
      },
    );
  },

  render() {
    const build = this.model;
    this.$el.html(
      this.template({
        isBiteOps: app.sessionUser.isBiteOps(),
        isBiteEng: app.sessionUser.isBiteEng(),
        name: build.displayName(),
        timestamp: TimeHelper.localMoment(build.get('createdAt')).format('MM/DD HH:mm'),
        availability: availabilityHtml(build),
        releaseNotes: build.get('releaseNotes').split('\n').join('<br />'),
        releaseHistory: releaseHistoryHtml(build.get('releaseHistory')),
        sentryUrl: `https://sentry.io/organizations/bite-inc/issues/?environment=${
          window.env
        }&project=${build.getSentryProjectId()}&query=is%3Aunresolved+release%3A${build.displayName()}&referrer=issue-list&statsPeriod=14d`,
      }),
    );
    this.$el.off('click');

    const detailsByContainer = {
      '.release-notes-container': '.release-notes',
      '.release-history-container': '.release-history',
    };
    _.each(detailsByContainer, (detailsSelector, containerSelector) => {
      const $toggle = this.$(`${containerSelector} .toggle`);
      const $arrow = $toggle.find('.arrow');
      const $details = this.$(detailsSelector);
      $details.hide();
      $toggle.click(() => {
        if ($details.is(':visible')) {
          $details.slideUp();
          $arrow.css('transform', 'rotate(0deg)');
        } else {
          $details.slideDown();
          $arrow.css('transform', 'rotate(180deg)');
        }
        return false;
      });
    });

    if (!build.hasArr('releaseHistory')) {
      this.$('.release-history-container').hide();
    }

    const $setLocationsButton = this.$('.button-container button.set-locations');
    $setLocationsButton.click((e) => {
      this._selectLocations($(e.target), build.get('locationIds'));
    });

    const $rejectBuildButton = this.$('.button-container button.reject-build');
    $rejectBuildButton.click((e) => {
      this._rejectBuild($(e.target));
    });

    const $turnOnGAButton = this.$('.button-container button.turn-on-ga');
    const $rollBackButton = this.$('.button-container button.roll-back');

    if (build.get('rejectedAt')) {
      $setLocationsButton.hide();
      $turnOnGAButton.hide();
      $rollBackButton.hide();
      $rejectBuildButton.hide();
    } else if (build.isNew()) {
      $turnOnGAButton.hide();
      $rollBackButton.hide();
    } else if (build.get('generalAvailability')) {
      $setLocationsButton.hide();
      $turnOnGAButton.hide();
      $rollBackButton.click((e) => {
        this._rollBack($(e.target));
      });
    } else if (build.get('locationIds').length) {
      $rollBackButton.click((e) => {
        this._rollBack($(e.target));
      });
      $turnOnGAButton.click((e) => {
        if (build.isPrereleaseBuild()) {
          new app.AlertView().show('This is a prerelease build. It cannot be generally available.');
          return;
        }
        const confirmed = app.promptUserToTypeInConfirmation(
          'Please type in "General Availability" to confirm that you want this build to be released to everyone (only locations using another GA build will be affected).',
          'general availability',
        );
        if (!confirmed) {
          return;
        }
        this._turnOnGeneralAvailability($(e.target));
      });
    } else {
      $rollBackButton.hide();
      $turnOnGAButton.hide();
    }

    return this;
  },
});
