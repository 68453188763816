import _ from 'underscore';

import { Validators } from '@biteinc/common';
import { LocationGroupDesignation, OrderChannel } from '@biteinc/enums';

import { buildOrderChannelTag, i9nBadgesHtml } from './html_builder';

const urlRegex = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
const mongoIdRegex = /"[a-zA-Z0-9]{24}"/gi;

app.HtmlHelper = {
  // closed padlock
  resellerRightIcon: '&#x00AE;&#x1f512;',
  // carrot
  biteRightIcon: '&#x1f955;&#x1f512;',

  visibleInHtml(visibleInPlaces) {
    return (
      _.map(visibleInPlaces, (place) => {
        if (_.isString(place)) {
          return place;
        }
        return `<a model-id="${place.id}" model-type="${
          place.Type
        }">${place.displayNameHtml()}</a>`;
      }).join(', ') || 'Currently not on the menu'
    );
  },

  // Accepts either an ordered item or an actual item model.
  createItemElement(itemModel) {
    const menuItem = (app.menuItemList && app.menuItemList.get(itemModel.id)) || itemModel;
    const imageUrl =
      menuItem.hasArr('images') && menuItem.get('images')[menuItem.get('images').length - 1].url;
    return (
      `<div class="item">` +
      `<div class="item-container">${
        imageUrl ? `<div class="image" style="background-image: url('${imageUrl}')"></div>` : ''
      }<div class="name">${menuItem.displayName()}</div>` +
      `</div>` +
      `</div>`
    );
  },

  preFromDict(dict, altText) {
    let pre = '<pre';
    if ((altText || '').length) {
      pre += ` alt="${altText}"`;
    }
    pre += `>${app.HtmlHelper._preTextFromDict(dict)}</pre>`;
    return pre;
  },

  _preTextFromDict(dict) {
    const string = JSON.stringify(dict, null, '  ');

    const lines = string.split('\n');
    let shift = 0;
    for (let i = 0; i < lines.length; i++) {
      const trimLine = lines[i].trim();
      lines[i] = trimLine;
      for (let j = 0; j < shift; j++) {
        lines[i] = `  ${lines[i]}`;
      }
      if (trimLine === '{') {
        // Own line {
        if (i !== 0) {
          lines[i - 1] = `${lines[i - 1]} {`;
        }
        lines.splice(i, 1);
        i--;
      } else if (
        trimLine.charAt(trimLine.length - 1) === '{' ||
        trimLine.charAt(trimLine.length - 1) === '['
      ) {
        // Line ends in { or [
        shift++;
      } else if (i < lines.length - 1 && (trimLine === '}' || trimLine === '},')) {
        shift--;
        const isOpeningBrace = lines[i + 1].trim().charAt(0) === '{';
        const isClosingBracket = lines[i + 1].trim().charAt(0) === ']';
        if (isOpeningBrace || isClosingBracket) {
          lines[i + 1] = `${trimLine} ${lines[i + 1].trim()}`;
          for (let k = 0; k < shift; k++) {
            lines[i + 1] = `  ${lines[i + 1]}`;
          }
          lines.splice(i, 1);
          if (isOpeningBrace) {
            i--;
          }
        } else {
          lines[i] = lines[i].substring(2);
        }
      } else if (trimLine.charAt(0) === '}' || trimLine.charAt(0) === ']') {
        shift--;
        if (i === lines.length - 1) {
          lines[i] = '';
        } else {
          lines[i] = lines[i].substring(2);
        }
      } else {
        lines[i] = app.HtmlHelper._replaceUrlWithLinks(lines[i]);
        lines[i] = app.HtmlHelper.replaceMongoIdWithLinks(lines[i]);
      }
    }
    return lines.join('\n').trim();
  },

  _replaceUrlWithLinks(text) {
    const urlMatches = text.match(urlRegex);
    return _.reduce(
      urlMatches,
      (replacedText, match) => {
        return replacedText.replace(match, `<a href="${match}" target="_blank">${match}</a>`);
      },
      text,
    );
  },

  replaceMongoIdWithLinks(text) {
    if (!text) {
      return '';
    }
    const mongoIdMatches = text.match(mongoIdRegex);
    return _.reduce(
      mongoIdMatches,
      (replacedText, match) => {
        const modelId = match.substring(1, match.length - 1);
        return replacedText.replace(match, `"<a model-id="${modelId}">${modelId}</a>"`);
      },
      text,
    );
  },

  // Copy from gcn_html
  stringFromCalorieRange(min, max) {
    const maxCaloriesStr = max && max !== min ? `-${max}` : '';
    return `${min}${maxCaloriesStr}`;
  },

  renderSearchHints(
    hintPrefix,
    keywordsByLabel,
    keywordUsageExample,
    $searchHint,
    $searchField,
    $searchButton,
    searchFieldEventToTriggerOnTokenClick, // 'focus' | 'search'
    countByKeyword = {},
    postTokenText = ' ',
  ) {
    let hintHtml = hintPrefix ? `${hintPrefix}<br />` : '';
    hintHtml += `Hint, you can use the following keywords (e.g. ${keywordUsageExample}):`;
    _.each(keywordsByLabel, (keywords, label) => {
      hintHtml += '<br />';
      hintHtml += `${label}: `;
      hintHtml += _.map(keywords, (keyword) => {
        const count = keyword in countByKeyword ? countByKeyword[keyword] : -1;
        const countStr = count >= 0 ? ` (${count})` : '';
        return `<i><span class="keyword">${keyword}</span>${countStr}</i>`;
      }).join(', ');
    });
    $searchHint.html(hintHtml);
    $searchHint.find('span.keyword').click(function onClick() {
      $searchField.val(`${$(this).text()}${postTokenText}${$searchField.val()}`);
      $searchField.trigger(searchFieldEventToTriggerOnTokenClick);
      $searchButton?.trigger('click');
    });
    $searchField.on('focus', () => {
      $searchHint.slideDown();
    });
  },

  locationNameWithChannelsAndIntegrations(location) {
    const orderChannelTag = buildOrderChannelTag(location.get('orderChannel'), {
      isLiveLocation: location.isLive(),
      isCanaryLocation: location.isCanary(),
    });
    return `${location.debugName()}&nbsp;&nbsp;${orderChannelTag}${i9nBadgesHtml(location.getAllI9nSchemas())}`;
  },

  getSiteLocations(site) {
    // There may be locationIds that the current user does not have access to.
    // e.g. a non-bite user wouldn't have access to bite-only locations
    return _.chain(site.get('locationIds'))
      .filter((locationId) => {
        return app.locationList.has(locationId);
      })
      .map((locationId) => {
        return app.locationList.get(locationId);
      })
      .sortBy((location) => {
        let sortKey = location.isLive() ? '_' : '';
        // Push catering to be last
        if (location.get('orderChannel') === OrderChannel.Catering) {
          sortKey += 'z';
        }
        return `${sortKey}${location.get('orderChannel')}`;
      })
      .value();
  },

  getSiteLocationGroups(site, locationGroupList) {
    if (!locationGroupList?.length) {
      return [];
    }
    const locations = app.HtmlHelper.getSiteLocations(site);
    const siteLocationIds = locations.map((location) => {
      return location.id;
    });

    return locationGroupList.filter((locationGroup) => {
      // Only look for manually created location groups
      // By default it seems that there are groups for each location and org wide
      if (locationGroup.get('designation') !== LocationGroupDesignation.ManuallyCreated) {
        return false;
      }

      // Make sure the location is included in our location group
      return siteLocationIds.some((locationId) => {
        return locationGroup.get('locationIds').some((id) => {
          return id === locationId;
        });
      });
    });
  },

  getSiteLocationGroupsNames(site, locationGroupList) {
    const siteLocationGroups = app.HtmlHelper.getSiteLocationGroups(site, locationGroupList);

    return siteLocationGroups
      .map((locationGroup) => {
        return locationGroup.get('name');
      })
      .sort();
  },

  siteNameWithIntegrationsHtml(site, locationGroupList) {
    const name = site.get('name');
    if (!app.locationList.hasBeenFetched()) {
      return name;
    }

    const locations = app.HtmlHelper.getSiteLocations(site);
    const hasAtLeastOneLiveLocation = _.any(locations, (location) => {
      return location.isLive();
    });

    const allI9nSchemas = _.chain(locations)
      .map((location) => {
        return location.getAllI9nSchemas();
      })
      .flatten()
      .value();

    // If we have location groups add them together and display at the end of the name
    const locationGroupNames = app.HtmlHelper.getSiteLocationGroupsNames(site, locationGroupList);
    const locationGroupsString =
      locationGroupNames.length > 0 ? `${name} [${locationGroupNames.join(', ')}]` : name;

    const nameWithLocationGroups = locationGroupsString || name;
    let nameInnerHtml = hasAtLeastOneLiveLocation
      ? `<b>${nameWithLocationGroups}</b>`
      : nameWithLocationGroups;
    const badgesHtml = i9nBadgesHtml(allI9nSchemas);
    if (badgesHtml) {
      nameInnerHtml += `${badgesHtml}`;
    }
    return nameInnerHtml;
  },

  /**
   * @param {string} hex
   * @returns {[number, number, number, number]}
   */
  rgbaFromHex(hex) {
    const regex = new RegExp(Validators.hexColorWithOptionalOpacityRegexString, 'i');
    if (regex.test(hex)) {
      let c = hex.substring(1).split('');
      for (let i = c.length; i < 8; i++) {
        c.push(i >= 6 ? 'F' : '0');
      }
      c = `0x${c.join('')}`;
      return [(c >> 24) & 255, (c >> 16) & 255, (c >> 8) & 255, (c & 255) / 255.0].join(',');
    }
    return null;
  },
};
