import async from 'async';
import qs from 'qs';
import _ from 'underscore';

import { Time } from '@biteinc/helpers';

import { cloneLocationOptionsSchema } from '../detail_views/schemas/clone_location_options_schema';
import { configurationAuditSchema } from './run_configuration_audit_schema';

app.LocationToolsView = app.BaseToolsView.extend({
  initialize(options) {
    this.options = options || {};
    this.listenTo(app.location, 'change', this.render);

    if (app.location.get('flashEnabled') && app.sessionUser.isBiteOps()) {
      this.listenToOnce(app.externalAuthStrategy, 'destroy', () => {
        window.location.reload();
      });
    }
  },

  _runLearningButtonWasClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Running', 'Done');
    const url = `${app.location.url()}/run-learning-service`;
    app.QueueHelper.makeRequest('POST', url, null, true, (err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToast('Learning Complete!', true);
      }
    });
  },

  _magicCopyButtonWasClicked(e) {
    const $button = $(e.target);
    async.auto(
      {
        locationIds: (cb) => {
          app.showLocationSelector(
            {
              modalTitle: 'Select destination locations to copy to (Step 1/2)',
              locationsUrl: '/api/v2/magic-copy/locations',
              locationGroupsUrl: '/api/v2/magic-copy/location-groups',
              maxSelectable: null,
            },
            cb,
          );
        },
        options: [
          'locationIds',
          (results, cb) => {
            const detailsView = new app.MagicCopyOptionsDetailsView({
              callback(model) {
                cb(null, model.options);
              },
              locationHasPos: !!app.location.getPosI9nSchema(),
            });
            app.modalManager.showModalWithView(detailsView);
          },
        ],
      },
      (err, results) => {
        const confirmed = app.promptUserToTypeInConfirmation(
          'Please type in "Magic Copy" to confirm that you want to perform magic copy to the locations you selected.',
          'magic copy',
        );
        if (!confirmed) {
          return;
        }

        const reqId = $button.initLoadingButton($button.html(), 'Copying', 'Copied');
        const url = '/api/v2/magic-copy/location';
        app.postRequest(
          url,
          {
            toLocationIds: results.locationIds,
            options: results.options,
          },
          (res) => {
            // If the timeout is not on the response, then Bureau was deployed before Maitred.
            // Fallback to a default string.
            // Once Maitred is deployed, the fallback can be removed.
            const timeoutStr = res.timeout
              ? ` within ${Math.ceil(res.timeout / Time.MINUTE)} minutes`
              : 'soon';

            $button.loadingDidFinishSuccessfully(reqId);
            new app.AlertView().show(
              `Magic Copy in progress!\nThe results will be sent to your email ${timeoutStr}.`,
            );
          },
          () => {
            $button.loadingDidFinishWithError(reqId);
          },
        );
      },
    );
  },

  _runConfigurationAuditButtonWasClicked(e) {
    const $button = $(e.target);
    const detailsView = new app.SchemaDetailsView({
      title: 'Select Audit Types to Run',
      saveButtonTitles: [' Run Configuration Audit', 'Auditing', 'Done!'],
      subProperty: 'options',
      schema: {
        fields: configurationAuditSchema.fields.options.fields,
      },
      callback(model) {
        const reqId = $button.initLoadingButton($button.html(), 'Auditing', 'Done');
        const url = `${app.location.url()}/configuration-audit`;
        app.QueueHelper.makeRequest(
          'POST',
          url,
          {
            locationId: app.location.id,
            options: model.options,
          },
          true,
          (err, res) => {
            if (err) {
              $button.loadingDidFinishWithError(reqId);
            } else {
              $button.loadingDidFinishSuccessfully(reqId);
              const resultsView = new app.MenuAuditResultListView({
                results: res,
              });
              let totalSuccess = 0;
              let totalFail = 0;
              _.each(res, (resultSection) => {
                if (_.size(resultSection)) {
                  _.each(resultSection, (result) => {
                    if (result.success) {
                      totalSuccess += 1;
                    } else {
                      totalFail += 1;
                    }
                  });
                }
              });
              const modallableView = new app.ModallableView({
                views: [resultsView],
                title: `Audit Results (${totalSuccess}/${totalFail + totalSuccess})`,
              });
              app.modalManager.showModalWithView(modallableView);
            }
          },
        );
      },
    });
    app.modalManager.showModalWithView(detailsView);
  },

  _cloneLocationButtonWasClicked(e) {
    const $button = $(e.target);
    async.auto(
      {
        locationIds: (cb) => {
          app.showLocationSelector(
            {
              modalTitle: 'Select a source location to clone to here (Step 1/2)',
              locationsUrl: `/api/locations?${qs.stringify({
                filter: 'clone',
                locationId: app.location.id,
              })}`,
              maxSelectable: 1,
            },
            cb,
          );
        },
        options: [
          'locationIds',
          (results, cb) => {
            const detailsView = new app.SchemaDetailsView({
              schema: cloneLocationOptionsSchema,
              title: 'Select attributes to copy over (Step 2/2)',
              saveButtonTitles: ['Clone', 'Cloning', 'Done!'],
              subProperty: 'options',
              callback: (model) => {
                cb(null, model.options || {});
              },
            });
            app.modalManager.showModalWithView(detailsView);
          },
        ],
      },
      (err, results) => {
        const confirmed = app.promptUserToTypeInConfirmation(
          'Please type in "Clone" to confirm that you want to wipe all the data on this location and clone it from the one you selected.',
          'clone',
        );
        if (!confirmed) {
          return;
        }

        const { locationIds, options } = results;
        const reqId = $button.initLoadingButton($button.html(), 'Cloning', 'Cloned');
        app.postRequest(
          `${app.location.url()}/clone`,
          {
            fromLocationId: locationIds[0],
            options,
          },
          () => {
            $button.loadingDidFinishSuccessfully(reqId);
            app.showSavedToastAndReload('Cloned!', true);
          },
          () => {
            $button.loadingDidFinishWithError(reqId);
          },
        );
      },
    );
  },

  _runDataAuditButtonWasClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Auditing', 'Audited!');
    const url = `${app.location.url()}/data-audit`;
    app.makeRequest(
      'POST',
      url,
      null,
      (data) => {
        $button.loadingDidFinishSuccessfully(reqId);
        if (data.summary) {
          new app.AlertView().show(data.summary, null, () => {
            window.location.reload();
          });
        }
      },
      () => {
        $button.loadingDidFinishWithError(reqId);
      },
    );
  },

  _runRefreshDayPartsButtonWasClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Refreshing', 'Refreshed!');
    const url = `/api/v2/locations/${app.location.id}/refresh-day-parts`;
    app.postRequest(
      url,
      null,
      () => {
        $button.loadingDidFinishSuccessfully(reqId);
        window.location.reload();
      },
      () => {
        $button.loadingDidFinishWithError(reqId);
      },
    );
  },

  _removeArchiveButtonWasClicked(e) {
    const msg =
      'Are you sure you want to delete all archived menu items, sections, mods, and mod groups?';
    if (!window.confirm(msg)) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Removing', 'Removed');
    app.menu.removeArchive({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success() {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Removed menu archive!', true);
      },
    });
  },

  _deleteMenuButtonWasClicked(e) {
    const msg =
      'Are you sure you want to delete the menu?\nThis will delete ' +
      'all items, sections, mods, etc.';
    if (!window.confirm(msg)) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Deleting', 'Deleted');
    app.location.deleteMenu({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success() {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Deleted the menu!', true);
      },
    });
  },

  _invalidateMenuCacheButtonWasClicked(e) {
    if (!window.confirm('Are you sure you want to invalidate the menu cache?')) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Invalidating', 'Invalidated');
    app.location.refreshMenuCache({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success() {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Cache is clear!', true);
      },
    });
  },

  _deleteLocationButtonWasClicked(e) {
    if (!window.confirm('Are you sure you want to delete this location?')) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Deleting', 'Deleted');
    app.location.destroy({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success(data) {
        $button.loadingDidFinishSuccessfully(reqId);
        const message = data.status || 'Deleted this location!';
        app.showSavedToastAndReload(message);
        setTimeout(() => {
          window.location = app.site.bureauUrl();
        }, 1500);
      },
    });
  },

  _compareLocationsButtonWasClicked(e) {
    const $button = $(e.target);
    async.auto(
      {
        locationIds: (cb) => {
          app.showLocationSelector(
            {
              modalTitle: 'Select other locations to compare',
              locationsUrl: '/api/v2/compare/locations',
              locationGroupsUrl: '/api/v2/compare/location-groups',
              maxSelectable: null,
            },
            cb,
          );
        },
      },
      (err, results) => {
        const reqId = $button.initLoadingButton($button.html(), 'Comparing', 'Compared');
        const url = '/api/v2/compare/locations';
        app.QueueHelper.makeRequest(
          'POST',
          url,
          {
            locationIds: results.locationIds,
          },
          true,
          (err) => {
            if (err) {
              $button.loadingDidFinishWithError(reqId);
            } else {
              $button.loadingDidFinishSuccessfully(reqId);
              app.showSavedToast('Check email for comparison reports');
            }
          },
        );
      },
    );
  },

  render() {
    app.BaseToolsView.prototype.render.apply(this, arguments);

    if (app.sessionUser.isBiteEng()) {
      this.addToolbarButton(
        'btn-primary',
        ' 🔮Run Learning Service 🔮',
        this._runLearningButtonWasClicked.bind(this),
      );
    }

    if (app.sessionUser.canMagicCopyLocation()) {
      this.addToolbarButton(
        'btn-danger',
        ' ✨Magic Copy to... ✨',
        this._magicCopyButtonWasClicked.bind(this),
      );
    }

    if (app.sessionUser.isBiteSupport()) {
      this.addToolbarButton(
        'btn-primary',
        'Audit Data Sanctity',
        this._runDataAuditButtonWasClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-primary',
        'Refresh Day Parts',
        this._runRefreshDayPartsButtonWasClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-primary',
        'Audit Menu/Configuration',
        this._runConfigurationAuditButtonWasClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-primary',
        'Compare Locations',
        this._compareLocationsButtonWasClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-danger',
        'Invalidate Menu Cache',
        this._invalidateMenuCacheButtonWasClicked.bind(this),
      );

      if (!app.location.isLive()) {
        this.addToolbarButton(
          'btn-danger',
          'Clone Location from...',
          this._cloneLocationButtonWasClicked.bind(this),
        );

        this.addToolbarButton(
          'btn-danger',
          '🗑 Remove Archived Menu Things',
          this._removeArchiveButtonWasClicked.bind(this),
        );
      }
    }

    if (app.sessionUser.isBiteOps()) {
      this.addToolbarButton(
        'btn-danger',
        'Delete This Menu',
        this._deleteMenuButtonWasClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-danger',
        'Delete This Location',
        this._deleteLocationButtonWasClicked.bind(this),
      );
    }

    if (app.location.get('flashEnabled') && app.sessionUser.isBiteOps()) {
      if (app.externalAuthStrategy.hasBeenFetched()) {
        const view = new app.AnchoredModalView({
          view: new app.BaseDetailsView({
            model: app.externalAuthStrategy,
            schema: app.externalAuthStrategy.Schema,
          }),
          isPermanent: true,
        });
        this.$el.append(view.render().$el);
      } else {
        this.listenToOnce(app.externalAuthStrategy, 'reset', this.render);
        app.externalAuthStrategy.fetch({ reset: true });
      }
    }

    return this;
  },
});
