import { LocationState } from '@biteinc/enums';

import type { BiteLevelOrgData, Org } from './types/org_data';

export function getCsvLinesFromOrgList(
  orgList: Backbone.Collection<Org<BiteLevelOrgData>>,
  query: string,
  countLiveLocationsThatMatchQuery: boolean,
  callback: { (err: Error | null, csvLines: string[][]): void },
): void {
  const lines: string[][] = [];
  lines.push(['Org Name', 'Admin Link', 'Live Location Count', 'Contact Info']);
  orgList.models.forEach((org) => {
    if (query && !org.matchesQuery(query)) {
      return;
    }

    const liveLocationCount = countLiveLocationsThatMatchQuery
      ? org.getLocationCountThatMatchesQuery(query, true)
      : org.get('locations')!.filter((location) => {
          return location.state === LocationState.Live;
        }).length;

    lines.push([
      org.get('name')!,
      `${window.location.origin}${org.bureauUrl()}`,
      `${liveLocationCount}`,
      org.get('contactInfo') || '',
    ]);
  });

  callback(null, lines);
}
