app.IntegrationList = app.AbstractCollection.extend({
  model: app.Integration,

  initialize() {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);

    this.listenTo(app.vendorList, 'reset', this.sort);
    this.listenTo(app.vendorList, 'change', this.sort);
    // Make sure there's an exception if someone initializes this before
    // vendorList.
    if (!app.vendorList) {
      this.crash();
    }
  },

  comparator(model) {
    // Sort pos i9ns, then others in alphabetical order
    let order = 3;
    if (model.getI9nSchema().type === 'pos') {
      order = 2;
    }
    return order + app.AbstractCollection.prototype.comparator.apply(this, arguments);
  },

  url() {
    return app.apiPathForLocation('/integrations');
  },
});
