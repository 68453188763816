import amplitude from 'amplitude-js';
import Backbone from 'backbone';
import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';

import { template } from '../template';

const SELECTED_TAB = 'selected';

app.TabbedView = Backbone.View.extend({
  template: template(
    // prettier-ignore
    '<div id="sidebar-scrim"></div>' +
    '<div id="sidebar-border"></div>' +
    '<div id="sidebar" class="no-user-select">' +
      '<div class="contents"></div>' +
    '</div>' +
    '<div class="component-tabs"></div>',
  ),
  className: 'tabbed-view',

  handleKeyDown(e) {
    if (!this.context) {
      return;
    }
    if (e.keyCode === 75 && e.metaKey) {
      if (app.sessionUser.isBite() && !this._biteSearchTool) {
        this._biteSearchTool = new app.BiteSearchView({
          context: this.context,
          onRemove: () => {
            this._biteSearchTool = null;
          },
        });
        $('body').append(this._biteSearchTool.render().el).find('input').focus();
      }
    }
    if (e.which === 27 && this._biteSearchTool) {
      this._biteSearchTool.remove();
      this._biteSearchTool = null;
    }
  },

  initialize() {
    this.sidebarElements = [];
    this.tabViews = [];
    this.tabViewsById = {};
    this.hasFooter = false;
    this.mobileMenuVisible = false;

    const self = this;
    $(window).on('resize', this._adjustAppHeight.bind(this));
    $(document).on('keydown', this.handleKeyDown.bind(this));
    window.onbeforeunload = function onbeforeunload() {
      if (!self.selectedTabView.canSuspend()) {
        return 'You have unsaved changes.';
      }
      const topModal = _.last(app.modalManager.modalStack);
      if (topModal && !topModal.canDismissModal()) {
        return 'Your modal has unsaved changes.';
      }
      return undefined; // returning null doesn't work on IE11
    };
  },

  setPageHeader(header) {
    this._header = header;
    header.$('.menu-button').click(() => {
      if (this.mobileMenuVisible) {
        this._hideMobileMenu();
      } else {
        this._showMobileMenu();
      }
    });
  },

  _hideMobileMenu() {
    if (!this.mobileMenuVisible) {
      return;
    }

    this.mobileMenuVisible = false;
    $('body').removeClass('modal-open');
    $('body').css('position', 'relative');
    this.$sidebar.stop().animate({ left: '-100%' }, 500, 'easeInOutExpo');
    this.$sidebarScrim.stop().fadeOut(500, 'easeInOutExpo');
  },

  _showMobileMenu() {
    if (this.mobileMenuVisible) {
      return;
    }

    this.mobileMenuVisible = true;
    $('body').addClass('modal-open');
    $('body').css('position', 'fixed');
    this.$sidebar.stop().animate({ left: '0' }, 500, 'easeInOutExpo');
    this.$sidebarScrim.stop().fadeIn(500, 'easeInOutExpo');
  },

  _adjustAppHeight() {
    const tabHeight = this.$el.height() - 40;
    // 40 is top/bottom margin
    // This magic number is also used in tab_view:_applyHeight
    _.each(this.tabViews, (tabView) => {
      if (tabView.supportsViewportMode) {
        tabView.setHeight(tabHeight);
      }
    });
  },

  addTab(tabView, name, id, index) {
    if (undefined === index) {
      // eslint-disable-next-line no-param-reassign
      index = -1;
    }

    if (!tabView.suspend) {
      alert(`tabview ${name} does not support suspend`);
    }
    if (!tabView.canSuspend) {
      alert(`tabview ${name} does not support canSuspend`);
    }
    if (!name && tabView.collection) {
      // eslint-disable-next-line no-param-reassign
      name = StringHelper.toTitleCase(tabView.collection.type());
    }
    if (!id && tabView.collection) {
      // eslint-disable-next-line no-param-reassign
      id = tabView.collection.type().split(' ').join('-');
    }
    this.tabViews.push(tabView);

    const className = `tab-${id}`;
    this.tabViewsById[id] = tabView;
    tabView.title = name;
    tabView.$el.addClass('component-tab');
    tabView.$el.addClass(className);
    tabView.$el.hide();

    const self = this;
    tabView.$tabTongue = $(`<div class="sb-el tab"><span>${name}</span></div>`);
    tabView.$tabTongue.addClass(className);
    tabView.$tabTongue.click(() => {
      if (self.selectedTabView.canSuspend()) {
        self.router.navigate(id, { trigger: true });
      }
      self._hideMobileMenu();
    });
    if (index >= 0) {
      self.sidebarElements.splice(index, 0, tabView.$tabTongue);
      tabView.$tabTongue.addClass('subtab');
    } else {
      self.sidebarElements.push(tabView.$tabTongue);
    }

    return className;
  },

  buildSubSchemaFromTabId(schema, tabId) {
    const subSchema = app.JsonHelper.deepClone(schema);
    _.each(subSchema.fields, (fieldSchema, fieldName) => {
      if (fieldSchema.tabId !== tabId) {
        delete subSchema.fields[fieldName];
      }
    });
    return subSchema;
  },

  addSettingsTab(model, schema, tabName, tabId, DetailsViewClass = app.BaseDetailsView) {
    const subSchema = this.buildSubSchemaFromTabId(schema, tabId);
    const tab = new app.TabView();
    const settingsView = new DetailsViewClass({
      model,
      schema: subSchema,
    });
    tab.setLeftView(settingsView, true);
    this.addTab(tab, tabName, tabId);
    return tab;
  },

  addBottomLinks(showHelpAndReleaseNotes, showChangePassword) {
    if (showHelpAndReleaseNotes) {
      this.addSpacer();
      const basicImg =
        '<img class="icon" src="https://static.bureau.getbite.com/images/icon-help.png" srcset="https://static.bureau.getbite.com/images/icon-help@2x.png 2x">';
      const hoverImg =
        '<img class="icon-hover" src="https://static.bureau.getbite.com/images/icon-help-hover.png" srcset="https://static.bureau.getbite.com/images/icon-help-hover@2x.png 2x">';
      const helpLink = `${basicImg}${hoverImg} Help`;
      this.addLinkWithHandler(helpLink, () => {
        window.open('https://getbite.zendesk.com/hc/en-us', '_blank');
      });
      this.addLinkWithHandler('Release Notes', () => {
        window.open('https://getbite.launchnotes.io/', '_blank');
      });
    }

    this.addSpacer();

    this.addSmallText(`Logged in as ${app.sessionUser.get('username')}`);

    if (showChangePassword) {
      this.addLinkWithHandler('Your Profile', () => {
        const detailsView = new app.UserDetailsView({
          model: app.sessionUser,
          schema: app.User.Schema(),
        });
        app.modalManager.showModalWithView(detailsView);
      });
    }

    this.addLinkWithHandler('Log Out', () => {
      const callback = () => {
        amplitude.getInstance().logEvent('Log Out');
        amplitude.getInstance().setUserId(null);
        amplitude.getInstance().regenerateDeviceId();
        amplitude.getInstance().clearUserProperties();
        window.location.href = '/';
      };
      app.postRequest('/api/auth/logout', null, callback, callback);
    });

    this.addFooter(`Copyright ${new Date().getFullYear()} Bite Inc`);
  },

  addSpacer() {
    this.sidebarElements.push($('<div class="sb-el spacer"></div>'));
  },

  addSeparatorWithTitle(title) {
    this.sidebarElements.push($(`<div class="sb-el separator">${title}</div>`));
  },

  addSmallText(text) {
    this.sidebarElements.push($(`<div class="sb-el small-text">${text}</div>`));
  },

  addLinkWithHandler(html, handler) {
    const $link = $(`<div class="sb-el link">${html}</div>`);
    $link.click(handler);
    this.sidebarElements.push($link);
  },

  addFooter(text) {
    this.hasFooter = true;
    this.sidebarElements.push($(`<div class="sb-el footer">${text}</div>`));
  },

  getSelectedTabView() {
    return this.selectedTabView;
  },

  /* eslint-disable no-param-reassign */
  routeTo(tabId, param) {
    const self = this;
    if ('default' !== tabId && (param || '').length > 1) {
      if ('menus/' === tabId) {
        if (app.menuStructureList.hasBeenFetched()) {
          const structure = app.menuStructureList.get(param);
          if (structure) {
            // eslint-disable-next-line no-param-reassign
            tabId = `menus/${param}`;
            if (!self.tabViewsById[tabId]) {
              const menuPagesView = new app.MenuPagesTabView({
                model: structure,
              });
              menuPagesView.subTabId = tabId;
              self.addTab(menuPagesView, structure.get('name'), tabId, 1);
              menuPagesView.$tabTongue.insertAfter(self.sidebarElements[0]);
              menuPagesView.$el.insertAfter(self.tabViews[0].$el);
              self._adjustAppHeight();
            }
            // eslint-disable-next-line no-param-reassign
            param = null;
          } else {
            tabId = 'menus';
          }
        } else {
          self.listenToOnce(app.menuStructureList, 'reset', () => {
            self.routeTo(tabId, param);
          });
          return;
        }
      } else {
        tabId = tabId.substr(0, tabId.length - 1);
      }
    }

    if ('nada' === tabId || 'default' === tabId) {
      tabId = this.defaultTabId;

      if (param?.startsWith('kiosk-orders')) {
        // We renamed the tab to remove any reference to kiosks.
        // To make sure old links still work, we need to rewrite the tabId.
        tabId = 'orders';
        param = param.substr('kiosk-orders'.length + 1);
      } else {
        // If there was no tab specified then the param makes no sense
        param = null;
      }
    }
    self.selectTabById(tabId, param);
  },
  /* eslint-enable no-param-reassign */

  selectTabById(tabId, param) {
    if (!this.tabViewsById[tabId]) {
      // eslint-disable-next-line no-param-reassign
      tabId = _.first(_.keys(this.tabViewsById));
    }

    const prevTabView = this.selectedTabView;
    const curTabView = this.tabViewsById[tabId];

    if (prevTabView !== curTabView) {
      if (prevTabView) {
        prevTabView.suspend();
      }
      this.selectedTabView = curTabView;
      curTabView.render();
      $('body').toggleClass('no-scroll', curTabView.supportsViewportMode);
    }

    // Update url (browser history) after suspending the old tab
    this._updateUrlTo(tabId, param);

    if (prevTabView) {
      prevTabView.$tabTongue.removeClass(SELECTED_TAB);
      prevTabView.$el.hide();

      if (prevTabView.subTabId) {
        delete this.tabViewsById[prevTabView.subTabId];
        prevTabView.destroy();
        prevTabView.$tabTongue.remove();
        prevTabView.$el.remove();
        for (let i = this.sidebarElements.length - 1; i >= 0; i--) {
          if (this.sidebarElements[i] === prevTabView.$tabTongue) {
            this.sidebarElements.splice(i, 1);
            break;
          }
        }
        for (let j = this.tabViews.length - 1; j >= 0; j--) {
          if (this.tabViews[j] === prevTabView) {
            this.tabViews.splice(j, 1);
            break;
          }
        }
      }
    }

    if (curTabView) {
      curTabView.$tabTongue.addClass(SELECTED_TAB);
      curTabView.$el.show();
      if (param) {
        curTabView.setSelectedElementId(param);
      }

      // Scroll the sidebar tab into view
      const tabY = curTabView.$tabTongue.position().top;
      const tabMaxY = tabY + curTabView.$tabTongue.outerHeight();
      const viewportY = this.$sidebar.scrollTop();
      const viewportMaxY = viewportY + this.$sidebar.outerHeight();
      let scrollTop = -1;
      if (viewportY > tabY - 60) {
        scrollTop = Math.max(0, tabY - 60);
      } else if (viewportMaxY < tabMaxY + 60) {
        scrollTop = Math.max(0, tabMaxY + 60 - this.$sidebar.outerHeight());
      }

      this.$sidebar.stop();
      if (scrollTop >= 0) {
        this.$sidebar.animate({ scrollTop }, 600);
      }
    }
  },

  _updateUrlTo(tabId, param) {
    if ((param || '').length) {
      // eslint-disable-next-line no-param-reassign
      tabId += `/${param}`;
    }

    if (this._header) {
      this._header.updateLocationUrlsWithTabId(tabId);
    }
    this.router.navigate(tabId, { trigger: false, replace: true });
  },

  _getTabIdForTabView(tabView) {
    const self = this;
    return _.find(_.keys(this.tabViewsById), (tabId) => {
      return self.tabViewsById[tabId] === tabView;
    });
  },

  updateUrlParam(param) {
    const tabId = this._getTabIdForTabView(this.selectedTabView);
    this._updateUrlTo(tabId, param);
  },

  render() {
    this.$el.html(this.template());

    this.$sidebar = this.$('#sidebar');
    this.$sidebarList = this.$sidebar.find('.contents');
    this.$sidebarScrim = this.$('#sidebar-scrim');
    this.$tabViewContainer = this.$('div.component-tabs');

    if (this.hasFooter) {
      this.$sidebar.addClass('with-footer');
    }

    this.$sidebarScrim.on('touchstart', this._hideMobileMenu.bind(this));

    _.each(this.sidebarElements, ($el) => {
      this.$sidebarList.append($el);
    });

    _.each(this.tabViews, (tabView) => {
      this.$tabViewContainer.append(tabView.$el);
    });

    this._adjustAppHeight();

    const routes = {};
    let defaultTabId = null;
    _.each(this.tabViewsById, (view, tabId) => {
      routes[tabId] = tabId;
      if (_.keys(routes).length === 1) {
        defaultTabId = tabId;
        routes[''] = 'nada';
      }

      if (view.supportsElementRouting) {
        routes[`${tabId}/:elementId`] = `${tabId}/`;
      }
    });
    routes['*default'] = 'default';
    this.defaultTabId = this.defaultTabId || defaultTabId;

    const AppRouter = Backbone.Router.extend({
      routes,
    });
    this.router = new AppRouter();
    app.router = this.router;
    _.each(routes, (tabId) => {
      this.router.on(`route:${tabId}`, (param) => {
        this.routeTo(tabId, param);
      });
    });

    Backbone.history.start();

    return this;
  },
});
