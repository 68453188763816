import Backbone from 'backbone';
import _ from 'underscore';

import { template } from '../../template';

app.AutoCompleteListView = Backbone.View.extend({
  tagName: 'div',
  className: 'autocomplete-list',
  template: template('<ul class="dropdown-menu"></ul>'),

  initialize(opts) {
    this.$input = opts.$input;
    this.listenTo(this.collection, 'reset', this.addAll);
    this.listenTo(app.autoCompleteManager, 'documentWasClicked', () => {
      this._hide();
    });
    this.cellViews = [];
  },

  destroy() {
    this.stopListening(app.autoCompleteManager);
    this.stopListening(this.collection);
    _.each(this.cellViews, (cell) => {
      this.stopListening(cell);
    });
  },

  render() {
    this.$el.html(this.template());
    this.$list = this.$el.find('ul');
    this._updateVisibility();

    this.$input.keydown((e) => {
      if (app.KEYCODE.TAB === e.keyCode) {
        this._hide();
      }
    });

    this.$input.keyup((e) => {
      if (e.ctrlKey) {
        return true;
      }

      switch (e.keyCode) {
        case app.KEYCODE.UP:
          this._userDidPressUp();
          break;
        case app.KEYCODE.DOWN:
          this._userDidPressDown();
          break;
        case app.KEYCODE.ENTER:
          this._userDidPressEnter();
          break;
        default: {
          const query = this.$input.val().trim();
          if (query === this.prevNameQuery) {
            break;
          }
          this.prevNameQuery = query;
          this.collection.searchFor(query);
        }
      }

      e.stopPropagation();
      return false;
    });

    this.$input.click((e) => {
      this._updateVisibility();
      e.stopPropagation();
      return false;
    });

    return this;
  },

  _userDidPressUp() {
    for (let i = 0; i < this.collection.size(); i++) {
      const model = this.collection.at(i);
      if (model.isSelected) {
        model.setSelected(false);
        const nextIndex = i > 0 ? i - 1 : this.collection.size() - 1;
        this.collection.at(nextIndex).setSelected(true);
        return;
      }
    }

    if (this.collection.size()) {
      this.collection.last().setSelected(true);
    }
  },

  _userDidPressDown() {
    for (let i = 0; i < this.collection.size(); i++) {
      const model = this.collection.at(i);
      if (model.isSelected) {
        model.setSelected(false);
        const nextIndex = i === this.collection.size() - 1 ? 0 : i + 1;
        this.collection.at(nextIndex).setSelected(true);
        return;
      }
    }

    if (this.collection.size()) {
      this.collection.first().setSelected(true);
    }
  },

  _userDidPressEnter() {
    this.collection.each((model) => {
      if (model.isSelected) {
        this._autoCompleteItemWasClicked(model);
      }
    });
  },

  _autoCompleteItemWasClicked(model) {
    this._hide();

    this.collection.each((m) => {
      m.setSelected(false);
    });

    this.trigger('autoCompleteItemWasClicked', model);
  },

  _updateVisibility() {
    if (this.collection.size() > 0) {
      this._show();
    } else {
      this._hide();
    }
  },

  _show() {
    this.$el.show();
    this.$el.addClass('open');
  },

  _hide() {
    this.$el.hide();
    this.$el.removeClass('open');
  },

  addOne(model) {
    const cell = new app.AutoCompleteCellView({ model });
    this.cellViews.push(cell);
    this.listenTo(cell, 'autoCompleteItemWasClicked', this._autoCompleteItemWasClicked);
    this.$list.append(cell.render().el);
  },

  addAll() {
    _.each(this.cellViews, (cell) => {
      this.stopListening(cell);
    });
    this.cellViews = [];
    this.$list.html('');
    this.collection.each(this.addOne, this);
    this._updateVisibility();
  },
});
