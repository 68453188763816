import { BiteLogLoyaltyEvent, BiteLogType } from '@biteinc/enums';
import { Time } from '@biteinc/helpers';

function getTitle(biteLog) {
  const type = biteLog.get('type');
  let deviceName = biteLog.get('clientName');
  if (type === BiteLogType.Kiosk && biteLog.get('kioskName')) {
    deviceName = biteLog.get('kioskName');
  }

  const location =
    app.location || (biteLog.get('locationId') && app.locationList?.get(biteLog.get('locationId')));
  const timezone = location?.get('timezone') || Time.BITE_TZ;
  const timestamp = Time.stringFromTimestamp(biteLog.get('createdAt'), timezone, true);

  return `[${timestamp}] ${deviceName}`;
}

app.BiteLogView = app.ModallableView.extend({
  className() {
    return 'bite-log-view';
  },

  initialize(options) {
    const biteLog = options.model;
    options.title = getTitle(biteLog);

    let description = biteLog.get('description');
    if (
      // show where loyalty coupon was redeemed when in org view
      biteLog.get('type') === BiteLogType.Loyalty &&
      [
        BiteLogLoyaltyEvent.CouponApplied,
        BiteLogLoyaltyEvent.CouponSingleRedemptionRedeemed,
      ].includes(biteLog.get('status')) &&
      app.locationList &&
      !app.location
    ) {
      const locationId = biteLog.get('locationId');
      const location = app.locationList.get(locationId);
      const locationName = location?.displayName();
      const orderId = biteLog.get('json')?.orderId;
      const orderUrl =
        location && orderId
          ? `${window.location.origin}/${location.attributes.orgNormalizedName}/${location.attributes.normalizedName}/${location.attributes.orderChannel}#orders/${orderId}`
          : '';

      if (orderId) {
        const orderLink = orderUrl
          ? `<a target="_blank" href="${orderUrl}">${orderId}</a>`
          : orderId;
        description += `\nRedeemed on Order: ${orderLink}\nAt ${locationName} (${locationId})`;
      }
    }

    options.views = [
      new app.JsonView({
        altText: 'Description',
        jsonValue: description,
      }),
    ];
    if (Object.keys(biteLog.get('json') || {}).length) {
      options.views.push(
        new app.JsonView({
          altText: 'JSON',
          jsonValue: biteLog.get('json'),
        }),
      );
    }
    options.primaryFooterButton = {
      title: 'Copy Link',
      isEnabled: true,
      callback: () => {
        const url = `${window.location.href.split('#')[0]}#system-logs/${biteLog.id}`;
        if (navigator.clipboard.writeText(url)) {
          app.showSavedToast('Copied Link to This System Log!');
        }
      },
    };
    options.secondaryFooterButtons = [
      {
        title: 'View JSON',
        callback: () => {
          const jsonView = new app.ModelJsonView({
            model: this.model,
            altText: 'Raw JSON',
            title: options.title,
          });
          app.modalManager.showModalWithView(jsonView);
        },
      },
      {
        title: 'Kiosk Status from around This Time',
        callback: () => {
          const createdAt = biteLog.get('createdAt');
          const afterAt = createdAt - Time.DAY;
          const kioskStatusList = new app.KioskStatusList([], {
            url() {
              return `${app.kioskStatusList.url()}?before=${createdAt}&after=${afterAt}`;
            },
          });
          const statusListView = new app.BaseListView({
            collection: kioskStatusList,
            cellView: app.KioskCellView,
            searchEnabled: false,
            canCreate: false,
          });
          const location =
            app.location ||
            (biteLog.get('locationId') && app.locationList?.get(biteLog.get('locationId')));
          const timezone = location?.get('timezone') || Time.BITE_TZ;

          const modallableView = new app.ModallableView({
            views: [statusListView],
            title: `Kiosk Status from around ${Time.stringFromTimestamp(
              createdAt,
              timezone,
              true,
            )}`,
          });
          app.modalManager.showModalWithView(modallableView);
        },
      },
    ];

    app.ModallableView.prototype.initialize.apply(this, arguments);
  },

  viewWasRemovedFromDom() {
    // This view is partly used as a details view, so to ensure that it gets cleaned up properly we
    // need to emit this event
    this.trigger(app.BaseDetailsView.Events.BaseDetailsViewDidClose, this);
  },

  render() {
    app.ModallableView.prototype.render.apply(this, arguments);

    const biteLog = this._options.model;
    if (biteLog.get('screenshotUrl')) {
      this.$el.append(`<img class="screenshot" src="${biteLog.get('screenshotUrl')}" />`);
    }

    return this;
  },
});
