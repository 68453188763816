import Backbone from 'backbone';
import _ from 'underscore';

import { LanguageCode } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';

app.FieldGroupView = Backbone.View.extend({
  className() {
    return 'field-group';
  },
  _viewHasBeenAddedToDom: false,

  initialize(options) {
    this.options = options;
    this.schema = options.schema;
    this.subProperty = options.subProperty;
    this.keyModel = options.keyModel;
    this.isReadOnly = options.isReadOnly;
    this.model = options.model;

    this.fieldViewsByField = {};
  },

  destroy() {
    this.model = null;
    _.each(this.fieldViewsByField, (fieldView) => {
      this.stopListening(fieldView);
      fieldView.destroy();
    });
  },

  setValue(value, model) {
    this.params = {};
    this.model = model;

    _.each(this.fieldViewsByField, (fieldView, field) => {
      if (fieldView.schema?.language) {
        const localizedFieldOriginalName = field.split('_')[0];
        fieldView.schema.localizedFieldOriginalName = localizedFieldOriginalName;

        const existingOverrides = value.localizationOverrides;
        const overrides = existingOverrides?.find((override) => {
          return override.language === fieldView.schema.language;
        });

        fieldView.setValue(overrides?.overrideByFieldName[localizedFieldOriginalName], model);
      } else {
        fieldView.setValue(value[field], model, value);
      }
    });
    _.each(this.fieldViewsByField, (fieldView) => {
      if (fieldView.schema.subgroupType === 'datepair') {
        const subgroupName = fieldView.schema.subgroupName;
        const hasAllFields = _.all(this.schema.fields, (schema, field) => {
          return schema.subgroupName !== subgroupName || !!value[field];
        });
        this.$subgroupDivByName[subgroupName].datepair({
          ...(hasAllFields && {
            defaultDateDelta: null,
            defaultTimeDelta: null,
          }),
        });
      }
    });
    _.each(value, (v, k) => {
      if (!this.fieldViewsByField[k]) {
        this.params[k] = v;
      }
    });
    this._updateFieldViewsWithConditions();
  },

  getValue() {
    const params = {};
    _.each(this.params, (v, k) => {
      if (k !== 'localizationOverrides') {
        params[k] = v;
      }
    });

    _.each(this.fieldViewsByField, (fieldView) => {
      const fieldValue = fieldView.getValue();
      if (
        (fieldValue !== null && fieldValue !== undefined) ||
        (fieldValue === null && fieldView.isNullValid())
      ) {
        if (!fieldView.schema.language) {
          params[fieldView.field] = fieldValue;
        } else {
          const indexOfLanguage = params.localizationOverrides?.findIndex((localeOverrides) => {
            return localeOverrides.language === fieldView.schema.language;
          });
          const languageOverrides = params.localizationOverrides?.find((languageOverride) => {
            return languageOverride.language === fieldView.schema.language;
          }) || {
            language: fieldView.schema.language,
            overrideByFieldName: {},
          };

          languageOverrides.overrideByFieldName[fieldView.schema.localizedFieldOriginalName] =
            fieldValue;

          if (indexOfLanguage >= 0) {
            params.localizationOverrides[indexOfLanguage] = languageOverrides;
          } else {
            params.localizationOverrides = [
              languageOverrides,
              ...(params.localizationOverrides || []),
            ];
          }
        }
      }
    });

    return params;
  },

  getParamWithoutFieldView(fieldName) {
    return this.params[fieldName];
  },

  setParamWithoutFieldView(fieldName, value) {
    this.params[fieldName] = value;
    this.trigger(app.FieldGroupView.Events.FieldGroupDidChangeValue, this);
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    this.hasValidated = true;
    return Object.values(this.fieldViewsByField).reduce(
      (response, fieldView) => {
        const fieldName = fieldView.getDisplayName();
        const { isValid, invalidFieldNames } = fieldView.checkValidity();
        if (isValid) {
          return response;
        }

        return {
          isValid: false,
          invalidFieldNames: [
            // previously encountered invalid field names
            ...(response.isValid ? [] : response.invalidFieldNames),
            // Either we have nested fields that are invalid or this current field is invalid by itself.
            ...(invalidFieldNames.length
              ? invalidFieldNames.map((nestedFieldName) => `${fieldName} > ${nestedFieldName}`)
              : [fieldName]),
          ],
        };
      },
      { isValid: true },
    );
  },

  viewWasAddedToDom() {
    if (!this._viewHasBeenAddedToDom) {
      this._viewHasBeenAddedToDom = true;
      _.each(this.fieldViewsByField, (fieldView) => {
        fieldView.viewWasAddedToDom();
      });
    }
  },

  _insertL10nFields(fields) {
    const languages = (app.locationSettings?.get('supportedLanguages') || []).filter(
      (language) => language !== LanguageCode.EN_US,
    );

    if (!languages.length) {
      return;
    }
    for (let i = fields.length - 1; i >= 0 && languages.length; i--) {
      const field = fields[i];
      if (field.schema.canHaveLocalizationOverrides) {
        const name = field.schema.displayName || StringHelper.toTitleCase(field.name);
        for (let j = 0; j < languages.length; j++) {
          fields.splice(i + j + 1, 0, {
            name: `${field.name}_${languages[j]}`,
            schema: {
              ...field.schema,
              isHidden:
                typeof this.options?.forceShowLocalizationFields === 'boolean'
                  ? !this.options.forceShowLocalizationFields
                  : field.schema.isHidden,
              language: languages[j],
              displayName: `${name} [${languages[j].toUpperCase()}]`,
            },
          });
        }
      }
    }
  },

  _getSettingsForLevel() {
    const settings = [];
    // Org and Site Level
    if (app.allLocationSettings) {
      settings.push(...app.allLocationSettings);
    }
    // Channel Level Only
    if (app.locationSettings) {
      settings.push(app.locationSettings);
    }
    return settings;
  },

  /** Filters custom strings schema for languages that are supported at the location(s). */
  _filterCustomL10nFields(fields) {
    const languages = _.chain(this._getSettingsForLevel())
      .map((setting) => {
        return setting.get('supportedLanguages');
      })
      .flatten()
      .uniq()
      .value();
    languages.push(LanguageCode.EN_US);
    for (let i = fields.length - 1; i >= 0; i--) {
      const field = fields[i];

      if (!languages.includes(field.name) && field.name !== 'value') {
        fields.splice(i, 1);
      }
    }
  },

  render() {
    const e1 = app.FieldView.Events.FieldWasAutoCompleted;
    const e2 = app.FieldView.Events.FieldDidChangeValue;
    this.$subgroupDivByName = {};
    const fields = _.map(this.schema.fields, (fieldSchema, field) => {
      return { name: field, schema: fieldSchema };
    });

    this._insertL10nFields(fields);

    if (this.subProperty === 'customStrings') {
      this._filterCustomL10nFields(fields);
    }

    _.each(fields, (f) => {
      const field = f.name;
      const fieldSchema = f.schema;

      if (fieldSchema.isHidden) {
        return;
      }
      if (this.model && this.model.fieldIsHidden(field, this.subProperty)) {
        return;
      }
      if (fieldSchema.biteOnly && !app.sessionUser.isBite()) {
        return;
      }
      if (fieldSchema.requiresTimings && (!app.menuTimingList || app.menuTimingList.size() < 2)) {
        return;
      }

      const FieldViewClass = this._classForField(fieldSchema);
      const fieldView = new FieldViewClass({
        field,
        schema: fieldSchema,
        subProperty: this.subProperty,
        keyModel: this.keyModel,
        isReadOnly:
          this.isReadOnly ||
          (fieldSchema.writeRight && !app.sessionUser.hasRight(fieldSchema.writeRight)) ||
          fieldSchema.isReadOnly,
        fieldGroupView: this,
        ...(this.model && this.model.getFieldHelpers(field)),
      });
      if (this.fieldViewsByField[field]) {
        this.stopListening(this.fieldViewsByField[field]);
        this.fieldViewsByField[field].destroy();
      }
      this.fieldViewsByField[field] = fieldView;

      if (fieldSchema.subgroupName) {
        let $subgroupDiv = this.$subgroupDivByName[fieldSchema.subgroupName];
        if (!$subgroupDiv) {
          $subgroupDiv = $('<div class="field-subgroup"></div>');
          $subgroupDiv.addClass(fieldSchema.subgroupName);
          this.$el.append($subgroupDiv);
          this.$subgroupDivByName[fieldSchema.subgroupName] = $subgroupDiv;
        }
        $subgroupDiv.append(fieldView.render().el);
      } else {
        this.$el.append(fieldView.render().el);
      }

      this.listenTo(fieldView, e1, this._fieldViewWasAutoCompleted);
      this.listenTo(fieldView, e2, this._fieldViewDidChangeValue);
    });

    return this;
  },

  _classForField(schema) {
    switch (schema.type) {
      case 'password':
        return app.PasswordFieldView;
      case 'map':
        return app.MapFieldView;
      case 'timestamp':
        return app.TimestampFieldView;
      case 'dayTimestamp':
        return app.DayTimestampFieldView;
      case 'array':
        switch (schema.ui) {
          case 'accordion':
            switch (schema.uiSubtype) {
              case 'AlertRules':
                return app.AlertRulesView;
              case 'BrinkDiscountByCardId':
                return app.BrinkDiscountByCardIdView;
              case 'BrinkDiscountMapByLoyaltyId':
                return app.BrinkDiscountMapByLoyaltyIdView;
              case 'FreshKdsOnPremDevices':
                return app.FreshKdsOnPremDevicesView;
              case 'CustomerIdentifiers':
                return app.CustomerIdentifiersView;
              case 'DateRange':
                return app.DateRangeAccordionView;
              case 'DaySchedule':
                return app.DayScheduleAccordionView;
              case 'DiningOptions':
                return app.DiningOptionsView;
              case 'FreeItemPromotions':
                return app.FreeItemPromotionsView;
              case 'FulfillmentStations':
                return app.FulfillmentStationsView;
              case 'HomeScreenImages':
                return app.HomeScreenImageView;
              case 'I9nDiningOptions':
                return app.I9nDiningOptionsView;
              case 'KioskPeripherals':
                return app.KioskPeripheralsView;
              case 'Links':
                return app.LinksView;
              case 'MdmSettings':
                return app.MdmSettingsView;
              case 'MenuItemDisplayBlockCarouselItems':
                return app.MenuItemDisplayBlockCarouselItemView;
              case 'MenuItemDisplayBlockGalleryItems':
                return app.MenuItemDisplayBlockGalleryItemView;
              case 'MenuItemDisplayBlockListItems':
                return app.MenuItemDisplayBlockListItemView;
              case 'MenuItemDisplayBlocks':
                return app.MenuItemDisplayBlockView;
              case 'OrderLeadTimeRule':
                return app.OrderLeadTimeRuleView;
              case 'OrderThrottlingRule':
                return app.OrderThrottlingRuleView;
              case 'PickupTimeSlots':
                return app.PickupTimeSlotsView;
              case 'PresetTips':
                return app.PresetTipsView;
              case 'PriceOptions':
                return app.PriceOptionsView;
              case 'OloCustomFields':
                return app.OloCustomFieldView;
              case 'OutpostLocations':
                return app.OutpostLocationsView;
              case 'Schedule':
                return app.ScheduleAccordionView;
              case 'SortPriorities':
                return app.SortPrioritiesView;
              case 'TaxRates':
                return app.TaxRatesView;
              case 'VirtualSubGroup':
                return app.VirtualSubGroupView;
              case 'WebhookSettings':
                return app.WebhookSettingsView;
            }
            return app.AccordionFieldView;
          case 'checklist':
            return app.CheckListFieldView;
          case 'extended-checklist':
            return app.ExtendedCheckListFieldView;
          case 'table':
            return app.ListFieldView;
        }
        if ('object' === schema.elementType) {
          if ('Image' === schema.elementSubtype) {
            return app.ImagesFieldView;
          }
          return app.ListFieldView;
        }
        return app.ArrayFieldView;
      case 'object':
        switch (schema.subtype) {
          case 'Address':
            return app.AddressFieldView;
          case 'CustomDomain':
            return app.CustomDomainView;
          case 'CustomDomainMetaData':
            return app.CustomDomainMetaDataView;
          case 'DateRange':
          case 'HourRange':
          case 'Schedule':
            return app.TimeRangeFieldView;
          case 'MenuItemAvailability':
            return app.MenuItemAvailabilityFieldView;
          case 'NutritionInfo':
            return app.NutritionInfoView;
        }
        return app.NestedObjectFieldView;
      default:
        switch (schema.ui) {
          case 'dropdown':
            return app.DropdownFieldView;
          case 'radiolist':
            return app.RadioListFieldView;
        }
    }
    return app.FieldView;
  },

  _fieldViewWasAutoCompleted(fieldView, acModel) {
    if (fieldView.schema.autoCompleteFields) {
      _.each(fieldView.schema.autoCompleteFields, (field) => {
        if (acModel.has(field)) {
          const value = acModel.get(field);
          this.fieldViewsByField[field].setValue(value, this.model);
        }
      });
    } else if (fieldView.schema.autoCompleteModel) {
      const optParams = {};
      const params = acModel.getParamsForSchema(this.schema, optParams);
      const value = _.extend({}, this.model.defaults(), optParams, this.getValue(), params);
      this.setValue(value, this.model);
      this.trigger(app.FieldGroupView.Events.FieldGroupDidChangeValue, this);
      if (this.fieldViewsByField.priceOptions) {
        this.fieldViewsByField.priceOptions.focusOnPrice();
      }
    }
  },

  _updateFieldViewsWithConditions() {
    _.each(this.fieldViewsByField, (fieldView) => {
      fieldView.$el.toggle(fieldView.doesSatisfyAllConditions());
    });
  },

  _fieldViewDidChangeValue() {
    this._updateFieldViewsWithConditions();
    this.trigger(app.FieldGroupView.Events.FieldGroupDidChangeValue, this);
  },
});

app.FieldGroupView.Events = {
  FieldGroupDidChangeValue: 'FieldGroupDidChangeValue',
};
