import Backbone from 'backbone';
import _ from 'underscore';

import { menuCoverSchema } from '@biteinc/schemas';

import { template } from '../../template';

app.MenuCoverPreviewView = Backbone.View.extend({
  template: template(
    // prettier-ignore
    '<div class="card-header">' +
      '<span class="title">Live Preview</span>' +
    '</div>' +
    '<div class="card-body"></div>',
  ),
  className: 'form-group card panel-default',

  initialize() {
    this.preview = new app.MenuCoverPreview();
  },

  render() {
    this.$el.html(this.template());
    this.$('.card-body').html(this.preview.render().$el);
    return this;
  },
});

app.MenuCoverTabView = app.TabView.extend({
  initialize() {
    app.TabView.prototype.initialize.apply(this, arguments);

    $(window).on('resize', this._adjustSizeToFitWindow.bind(this));

    const self = this;
    this.menuCoverListView = new app.BaseListView({
      collection: app.menuCoverList,
      searchEnabled: false,
      fetchOnInit: false,
      detailsView: app.BaseDetailsView,
      showDetailsView(detailsView) {
        if (!self.menuCoverAnchorView || self.menuCoverAnchorView.canDismissModal()) {
          const anchorView = new app.AnchoredModalView({
            view: detailsView,
          });
          const evt = app.AnchoredModalView.Events.AnchoredModalNeedsToClose;
          self.leftView.replaceViewAtIndex(2, anchorView);
          self.detailsView = detailsView;
          self.menuCoverAnchorView = anchorView;
          self.listenTo(self.menuCoverAnchorView, evt, self._menuCoverAnchoredModalNeedsToClose);
          self.setRightView(self.previewView);
          self.render();
          self.listenTo(
            detailsView.fieldGroupView,
            app.FieldGroupView.Events.FieldGroupDidChangeValue,
            self._fieldGroupViewDidChangeValue,
          );
          return true;
        }
        return false;
      },
    });

    this.menuCoverPromoListView = new app.BaseListView({
      collection: app.menuCoverPromoList,
      fetchOnInit: false,
    });

    this.leftView = new app.MultiView({
      views: [this.menuCoverListView, this.menuCoverPromoListView],
    });
    this.listenTo(
      this.leftView,
      app.MultiView.Events.MultiViewWillRemoveView,
      this._multiViewWillRemoveView,
    );
    this.setLeftView(this.leftView);

    this.previewView = new app.MenuCoverPreviewView();
  },

  supportsViewportMode: false,

  _multiViewWillRemoveView(multiView, view) {
    if (this.menuCoverAnchorView === view) {
      this.stopListening(this.menuCoverAnchorView);
      this.stopListening(this.detailsView.fieldGroupView);
      this.menuCoverAnchorView = null;
      this.detailsView = null;
      this.setRightView(null);
      this.render();
    }
  },

  _menuCoverAnchoredModalNeedsToClose() {
    this.leftView.removeViewAtIndex(2);
  },

  _adjustSizeToFitWindow() {
    if (!this.$rightPanel || !this.detailsView) {
      return;
    }

    const panelWidth = this.$rightPanel.width();
    const coverWidth = panelWidth - 15 * 2 - (panelWidth % 2 ? 1 : 0);

    let size = menuCoverSchema.fields.coverImg.size;
    const coverAttributes = this.detailsView.fieldGroupView.getValue();
    if (_.size(coverAttributes.coverImg1080)) {
      size = menuCoverSchema.fields.coverImg1080.size;
    }

    const width = size.width / 2;
    const height = size.height / 2;
    const ratio = width / height;
    const coverHeight = parseInt(coverWidth / ratio, 10);
    this.previewView.preview.$el.css('width', `${coverWidth}px`);
    this.previewView.preview.$el.css('height', `${coverHeight}px`);
    this.previewView.preview.setScale(coverWidth / width);
  },

  _fieldGroupViewDidChangeValue() {
    const coverAttributes = this.detailsView.fieldGroupView.getValue();
    this._adjustSizeToFitWindow(); // Show the start button in the right place
    this.previewView.preview.setParams(coverAttributes);
  },

  render() {
    app.TabView.prototype.render.apply(this, arguments);

    const self = this;
    setTimeout(() => {
      self._adjustSizeToFitWindow();
    }, 1);

    if (this.detailsView) {
      const coverAttributes = this.detailsView.fieldGroupView.getValue();
      this.previewView.preview.setParams(coverAttributes);
    }

    return this;
  },
});
