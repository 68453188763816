import { LocationState } from '@biteinc/enums';

import { buildOrderChannelTag } from '../../helpers/html_builder';

app.SiteListView = app.BaseListView.extend({
  initialize(options) {
    options.cellOptions = {
      getNameUrl(site) {
        const nameInnerHtml = app.HtmlHelper.siteNameWithIntegrationsHtml(
          site,
          app.locationGroupList,
        );
        const channelTagHtml = app.HtmlHelper.getSiteLocations(site).reduce((html, location) => {
          const channelTag = buildOrderChannelTag(location.get('orderChannel'), {
            isLiveLocation: location.isLive(),
            isCanaryLocation: location.isCanary(),
          });
          return `${html}&nbsp;${channelTag}`;
        }, '&nbsp;');

        return {
          href: site.bureauUrl(),
          alt: site.get('name'),
          innerHtml: `${nameInnerHtml}${channelTagHtml}`,
        };
      },
    };

    this.listenTo(app.locationList, 'reset', (/* e */) => {
      if (this.$el.html().length) {
        this.render();
      }
    });

    app.BaseListView.prototype.initialize.apply(this, arguments);
  },

  getListSections() {
    const biteOnlySites = [];
    const nonBiteSites = [];
    this.collection.models.forEach((site) => {
      if (site.get('biteOnly')) {
        biteOnlySites.push(site);
        return;
      }
      nonBiteSites.push(site);
    });
    const liveLocations = app.locationList.models.filter((location) => {
      return location.get('state') === LocationState.Live;
    });
    const liveSiteSet = new Set();
    liveLocations.forEach((location) => {
      liveSiteSet.add(location.get('siteId'));
    });
    const liveLocationCount = liveLocations.length;
    const liveSiteCount = liveSiteSet.size;
    const liveSuffix =
      liveLocationCount > 0
        ? ` (${liveLocationCount} Live Channel${
            liveLocationCount === 1 ? '' : 's'
          } @ ${liveSiteCount} site${liveSiteCount === 1 ? '' : 's'})`
        : '';
    return [
      {
        header: `Client Sites${liveSuffix}`,
        models: nonBiteSites,
      },
      {
        header: 'Bite-Only Sites',
        models: biteOnlySites,
      },
    ];
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    if (!app.locationGroupList.hasBeenFetched()) {
      this.listenToOnce(app.locationGroupList, 'reset', this.render);
    }

    const createButton = this.$('.card-header .right-button-container button.create');
    createButton.html(`${createButton.html()}${app.HtmlHelper.resellerRightIcon}`);

    return this;
  },
});
