import _ from 'underscore';

import { I9nSchemaBySystem } from '@biteinc/common';
import type { I9nSchema } from '@biteinc/common';
import { OrderChannel, OrderChannelHelper } from '@biteinc/enums';
import type { ModelType } from '@biteinc/enums';

import { TimeHelper } from './time_helper';

export function getArchivedBadgeHtml(
  archivedAt: number | undefined,
  isModGroup: boolean,
): string | null {
  if (!archivedAt) {
    return null;
  }

  let alt = `Archived on ${TimeHelper.format(archivedAt)}.`;
  if (isModGroup) {
    alt += ' Will be removed a week after that.';
  } else {
    alt += ' Will be kept indefinitely.';
  }
  return `<span class="cell-badge archived" title="${alt}">archived</span>`;
}

export function getBadgeHtml(i9nSchema: I9nSchema, modelType?: ModelType): string {
  const title = `${I9nSchemaBySystem[i9nSchema.system].name}${modelType ? ` ${modelType}` : ''}`;
  return `<img class="badge-img" src="${i9nSchema.iconPath}" title="${title}"/>`;
}

export function i9nBadgesHtml(i9nSchemas: I9nSchema[], delimiter: string = '&nbsp;'): string {
  return _.chain(i9nSchemas)
    .uniq()
    .sortBy('name')
    .reduce((name, i9nSchema) => {
      return `${name}${delimiter}${getBadgeHtml(i9nSchema)}`;
    }, delimiter)
    .value();
}

function tagCssClassFromOrderChannel(orderChannel: OrderChannel): string {
  switch (orderChannel) {
    case OrderChannel.Catering:
      return 'catering';
    case OrderChannel.DriveThru:
      return 'drive-thru';
    case OrderChannel.Flash:
      return 'contactless';
    case OrderChannel.Kiosk:
      return 'kiosk';
    case OrderChannel.Linebuster:
      return 'linebuster';
    case OrderChannel.Web:
      return 'web';
  }
}

export function buildOrderChannelTag(
  orderChannel: OrderChannel,
  options: {
    isLiveLocation: boolean;
    isCanaryLocation: boolean;
    includeArrowIcon?: boolean;
  },
): string {
  let cssClass = tagCssClassFromOrderChannel(orderChannel);
  if (options.isLiveLocation) {
    cssClass += ' live';
  }
  const orderChannelName = OrderChannelHelper.nameCombiningKioskAndFlash(orderChannel);
  const canaryDesignation = options.isCanaryLocation ? '✪ ' : '';
  const arrowIcon = options.includeArrowIcon ? ' ⇨' : '';
  return `<span class="order-channel-tag ${cssClass}">${canaryDesignation}${orderChannelName}${arrowIcon}</span>`;
}
