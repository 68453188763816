import { Validators } from '@biteinc/common';
import { ModelType } from '@biteinc/enums';
import { menuCoverSchema } from '@biteinc/schemas';

app.MenuCover = app.AbstractModel.extend({
  ModelName: 'menuCover',
  Schema: menuCoverSchema,
  Type: ModelType.MenuCover,

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'menuCoverPromoIds':
        return app.menuCoverPromoList;
    }
    return null;
  },

  validateField(field, value, callback) {
    switch (field) {
      case 'delphiTextColor': {
        const passes = new RegExp(Validators.hexColorWithOptionalOpacityRegexString, 'i').test(
          value,
        );
        if (!passes) {
          callback(
            false,
            'Please use RGB(A) format: #FF0000 for red or #FF00007F for red with 50% alpha.',
          );
          return false;
        }
        callback(true);
        break;
      }
      default:
        callback(true);
    }
    return true;
  },
});
