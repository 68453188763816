import _ from 'underscore';

import { getBadgeHtml } from '../../helpers/html_builder';

app.IntegrationListView = app.BaseListView.extend({
  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.collection, 'change', this._updateIfCanCreate);
  },

  _updateIfCanCreate() {
    this.setCanCreate(app.location.getCreatableI9nSchemas().length > 0);
  },

  getListSections() {
    const posI9ns = [];
    const menuSupplementI9ns = [];
    const paymentI9ns = [];
    const compCardI9ns = [];
    const storedValueI9ns = [];
    const loyaltyI9ns = [];
    const fulfillmentI9ns = [];
    const otherI9ns = [];

    _.each(this.collection.models, (i9n) => {
      /** @type import('@biteinc/common').I9nSchema */
      const i9nSchema = i9n.getI9nSchema();
      switch (i9nSchema.type) {
        case 'ecomm-payment':
        case 'kiosk-payment':
          paymentI9ns.push(i9n);
          break;
        case 'fulfillment':
          fulfillmentI9ns.push(i9n);
          break;
        case 'loyalty':
          loyaltyI9ns.push(i9n);
          break;
        case 'pos':
          posI9ns.push(i9n);
          break;
        case 'stored-value':
          storedValueI9ns.push(i9n);
          break;
        case 'supplementary-menu-source':
          menuSupplementI9ns.push(i9n);
          break;
        case 'comp-card':
          compCardI9ns.push(i9n);
          break;
        case 'email-notifier':
        case 'marketing':
        case 'sms-notifier':
        default:
          otherI9ns.push(i9n);
      }
    });

    const sections = [];
    if (posI9ns.length) {
      sections.push({ header: 'POS', models: posI9ns });
    }
    if (menuSupplementI9ns.length) {
      sections.push({ header: 'Menu Supplements', models: menuSupplementI9ns });
    }
    if (paymentI9ns.length) {
      sections.push({ header: 'Payment', models: paymentI9ns });
    }
    if (storedValueI9ns.length) {
      sections.push({ header: 'Comp Card', models: compCardI9ns });
    }
    if (storedValueI9ns.length) {
      sections.push({ header: 'Stored Value', models: storedValueI9ns });
    }
    if (loyaltyI9ns.length) {
      sections.push({ header: 'Loyalty', models: loyaltyI9ns });
    }
    if (fulfillmentI9ns.length) {
      sections.push({ header: 'Fulfillment', models: fulfillmentI9ns });
    }
    if (otherI9ns.length) {
      sections.push({ header: 'Others', models: otherI9ns });
    }
    return sections;
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (this.options.canCreate) {
      const self = this;
      const schemas = app.location.getCreatableI9nSchemas();
      const SchemaModel = app.AbstractModel.extend({
        displayNameHtml() {
          const title = getBadgeHtml(this.attributes);
          return `${title}<span>${this.displayName()}</span>`;
        },
      });
      const collection = app.AbstractCollection.createFromEnum({
        schema: schemas,
        ModelClass: SchemaModel,
        sort: app.AbstractCollection.SortOptions.NAME,
      });
      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });
      this.$('.card-header .right-button-container').html(dropdownView.render().$el);
      dropdownView.setTitle(
        `<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;new integration ${app.HtmlHelper.resellerRightIcon}`,
      );
      dropdownView.setup(collection, {
        onAdd(i9nSchema) {
          // eslint-disable-next-line new-cap
          const model = new self.collection.model(
            {
              system: i9nSchema.id,
            },
            {
              collection: self.collection,
            },
          );
          self.editModel(model);
        },
      });

      if (app.sessionUser.canManageFullI9ns()) {
        const posI9n = _.find(this.collection.models, (i9n) => {
          return i9n.getI9nSchema().type === 'pos';
        });

        if (posI9n) {
          const $toolbar = $(
            // prettier-ignore
            '<div class="container integration-toolbar">' +
              '<div class="btn-group">' +
                '<div class="btn btn-secondary btn-sm sync-menus">' +
                  '<i class="bi bi-arrow-clockwise"></i>&nbsp;Sync Menus' +
                '</div>' +
              '</div>' +
            '</div>',
          );
          this.$listHeader.html($toolbar);
          const $button = $toolbar.find('.btn.sync-menus');
          $button.click(() => {
            const reqId = $button.initLoadingButton($button.html(), 'Syncing', 'Synced');
            // Endpoint will sync all pos-i9ns if we sync one of them
            posI9n.syncI9n((err, response) => {
              if (err) {
                $button.loadingDidFinishWithError(reqId);
              } else if (response?.message) {
                new app.AlertView().show(response.message, null, () => {
                  window.location.reload();
                });
                $button.loadingDidFinishWithError(reqId);
              } else {
                $button.loadingDidFinishSuccessfully(reqId);
                app.showSavedToastAndReload('Synced!', true);
              }
            });
          });
        }
      }
    }

    return this;
  },
});
