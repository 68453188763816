import _ from 'underscore';

import { ModelType } from '@biteinc/enums';

import { template } from '../../template';

app.ListFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} list-field-view`;
  },
  template: template($('#list-field-view-template').html()),

  destroy() {
    app.FieldView.prototype.destroy.apply(this, arguments);
    this.stopListening(this.collection);
    if (this.dropdown) {
      this.dropdown.destroy();
      this.dropdown = null;
    }
  },

  checkMaybeShowEmptyMessage() {
    const $emptyListWarning = this.$itemPanel.find('div.empty-list-warning');
    if (this.schema.emptyListWarningHtml && !_.size(this.list)) {
      $emptyListWarning.html(`<span class="warning">${this.schema.emptyListWarningHtml}</span>`);
      $emptyListWarning.show();
    } else {
      $emptyListWarning.hide();
    }
  },

  _updateDropdownToggle() {
    const disabled = this.collection.length < 1;
    this.$dropdownToggle.prop('disabled', disabled);
  },

  _setupDropdown() {
    const self = this;
    const dropdown = new app.Dropdown();
    dropdown.setupDropdown(this.$dropdownPanel, this.collection, {
      getDisplayName: self._getDisplayNameForElement.bind(self),
      validate(element) {
        // allow for archived sections to display in menu structures
        if (self.model.Type !== ModelType.MenuStructure && element.get('archivedAt')) {
          return false;
        }
        if (self.schema.elementType === 'object') {
          return _.all(self.list, (listElement) => {
            return listElement._id !== element.id;
          });
        }
        return self.list.indexOf(element.id) < 0;
      },
      onAdd(element) {
        if (self.schema.elementType === 'object') {
          self.list.push(
            self.model.getListFieldElementAttributesFromModel(
              self.field,
              element,
              self.subProperty,
              self.keyModel,
            ),
          );
        } else {
          self.list.push(element.id);
        }
        self._renderList();
        self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
      },
    });
  },

  _setupRows() {
    const self = this;
    if (this.dropdown) {
      this.dropdown.destroy();
    }
    let readOnly = this.isReadOnly;

    // If we are preserving the mod group order in our items we cannot allow the user to reorder
    let rowReadOnly;
    if (this.field === 'addonSetIds') {
      const fullI9n = app.location.getFullPosI9n();
      if (fullI9n) {
        rowReadOnly = !!fullI9n.get('preserveItemModGroupOrder');
      }
    }

    this.dropdown = new app.Dropdown();
    const options = {
      getDisplayName: self._getDisplayNameForElement.bind(self),
      getSubtitle: self._getSubtitleForElement.bind(self),
      isReadOnly: rowReadOnly ?? readOnly,
      onRemove(object) {
        let index = -1;
        if (self.schema.elementType === 'object') {
          for (let i = 0; i < self.list.length; i++) {
            if (self.list[i]._id === object.id) {
              index = i;
              break;
            }
          }
        } else {
          index = self.list.indexOf(object.id);
        }
        if (index >= 0) {
          self.list.splice(index, 1);
          self._renderList();
          self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
        }
      },
      canBeRemoved() {
        return (
          !self.model.fieldIsPermanent(self.field) &&
          (!self.schema.minCount || self.schema.minCount < self.list.length) &&
          (!self.schema.writeRight || app.sessionUser.hasRight(self.schema.writeRight))
        );
      },
      canBeMoved() {
        return !self.schema.writeRight || app.sessionUser.hasRight(self.schema.writeRight);
      },
      onMove(oldIndex, newIndex) {
        const object = self.list[oldIndex];
        self.list.splice(oldIndex, 1);
        self.list.splice(newIndex, 0, object);
        self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
      },
    };

    const DetailsView = this.model.detailsViewClassForListField(
      this.field,
      this.subProperty,
      this.keyModel,
    );
    if (DetailsView) {
      options.onClick = function onClick(object) {
        if (!object.collection) {
          // eslint-disable-next-line no-param-reassign
          object = self.model.getFieldModelForValue(
            self.field,
            object.id,
            self.subProperty,
            self.keyModel,
          );
        }
        if (object && object.collection) {
          const detailsView = new DetailsView({
            model: object,
            collection: object.collection,
            isReadOnly: readOnly,
          });
          app.modalManager.showModalWithView(detailsView);
        }
      };
    }

    this.dropdown.setupRows(this.$itemPanel, this.list, this.collection, options);
  },

  setValue(list, model) {
    this.model = model;
    this.initialValue = list;

    if (!this.collection) {
      this.collection = model.getFieldCollection(
        this.field,
        this.subProperty,
        false,
        this.keyModel,
      );
      this.listenTo(this.collection, 'add', this._updateDropdownToggle);
      this.listenTo(this.collection, 'reset', this._updateDropdownToggle);
      this.listenTo(this.collection, 'reset', this._renderList);
    }

    // Create a new array object so that the pointer would be different
    this.list = [].concat(list || []);

    this._renderList();
    this.toggleCollapsedView(!this.list.length);
  },

  _getDisplayNameForElement(element) {
    if (this.model && this.model.displayNameForListFieldElement) {
      const displayName = this.model.displayNameForListFieldElement(
        this.field,
        element,
        this.subProperty,
        this.keyModel,
      );
      if (displayName) {
        return displayName;
      }
    }
    if (element.displayNameHtml) {
      return element.displayNameHtml();
    }
    return element.displayName();
  },

  _getSubtitleForElement(element) {
    if (this.model && this.model.subtitleForListFieldElement) {
      return this.model.subtitleForListFieldElement(this.field, element);
    }
    // empty strings will not show as subtitles
    return '';
  },

  _renderList() {
    // Incase we have an old collection element in the list
    // that doesn't exist in the collection anymore
    const collectionElementsInList = [];
    _.forEach(this.list, (_id) => {
      const element = this.collection._byId[_id];
      if (element) {
        collectionElementsInList.push(element);
      }
    });
    if (
      collectionElementsInList.length !== this.collection.size() &&
      !this.model.fieldIsPermanent(this.field)
    ) {
      this.$dropdownPanel.show();
      this._setupDropdown();
    } else {
      this.$dropdownPanel.hide();
    }

    this.checkMaybeShowEmptyMessage();
    this._setupRows();
  },

  getValue() {
    const list = [].concat(this.list);
    if (!list.length && !this.initialValue) {
      return this.initialValue;
    }
    return list;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    return { isValid: true };
  },

  render() {
    this.$el.html(this.template());

    this.$itemPanel = this.$el.find('.item-panel');
    this.$dropdownPanel = this.$el.find('.select-panel');
    this.$dropdownToggle = this.$itemPanel.find('button.dropdown-toggle');

    this.$el.find('.card-header').html(this.getDisplayName());
    this.$dropdownToggle.find('.text').text(`${this.schema.placeholder}:`);

    const $warning = this.$itemPanel.find('div.warning');
    if (this.schema.warningHtml) {
      $warning.html(this.schema.warningHtml);
    } else {
      $warning.remove();
    }

    if (this.isPermanent()) {
      this.$dropdownToggle.hide();
    }

    this.checkMaybeShowEmptyMessage();
    if (this.schema.collapse) {
      this.addCollapseButton(this.getDisplayName());
    }

    return this;
  },
});
