import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';

app.ModelSelectorListView = app.BaseListView.extend({
  initialize(options) {
    app.BaseListView.prototype.initialize.apply(this, [
      {
        canCreate: false,
        ...(options || {}),
      },
    ]);

    this.selectedModelIdSet = {};
    this._pendingModelIds = [];

    this.listenToOnce(this.collection, 'reset', () => {
      this.selectModelIds(this._pendingModelIds);
      this._pendingModelIds = [];
    });
  },

  getListSections() {
    const sections = app.BaseListView.prototype.getListSections.apply(this);
    return sections.map((section) => {
      return {
        // This header is not necessary for UI since we will only have one section, but it's the one
        // place where we can place a "select all button".
        header: `All ${StringHelper.pluralize(this.collection.getSchema().displayName)}`,
        ...section,
        allowAllToBeSelected: true,
      };
    });
  },

  selectModelIds(modelIds) {
    if (!this.collection.hasBeenFetched()) {
      _.each(modelIds, (locationId) => {
        this._pendingModelIds.push(locationId);
      });
      return;
    }

    let hasAdded = false;
    _.each(modelIds, (modelId) => {
      if (this.collection.get(modelId) && !this.selectedModelIdSet[modelId]) {
        this.selectedModelIdSet[modelId] = true;
        this.getCellByModelId(modelId).setSelected(true);
        hasAdded = true;
      }
    });
    if (hasAdded) {
      this.trigger(app.ModelSelectorListView.Events.SelectionDidChange, this);
    }
  },

  // Make sure that we mark any new cell as selected (e.g. after search)
  createCell(model) {
    const cell = app.BaseListView.prototype.createCell.apply(this, arguments);
    if (this.selectedModelIdSet[model.id]) {
      cell.setSelected(true);
    }
    return cell;
  },

  __generateTableSectionHeader(listSection, matchingModels) {
    const $tableSectionHeader = app.BaseListView.prototype.__generateTableSectionHeader.apply(
      this,
      arguments,
    );
    if (this.options.maxSelectable === null && listSection.allowAllToBeSelected) {
      $tableSectionHeader.append(`
        <span class="right-button-container">
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-sm btn-primary select-all">
              Select All Below
            </button>
          </div>
        </span>
      `);
      $tableSectionHeader.find('.btn.select-all').click(() => {
        this.selectModelIds(
          matchingModels.map((model) => {
            return model.id;
          }),
        );
      });
    }
    return $tableSectionHeader;
  },

  __cellWasClicked(cellView, eventOpt) {
    const modelId = cellView.model.id;

    // We will have special logic if the shift key was held.
    // 1. Find the section the model belongs to
    const listSection = this.getListSections().find(({ models }) => {
      return !!models.find((model) => model.id === modelId);
    });
    // 2. If the section has other models selected, then the shift key has an effect.
    const listSectionHasOtherSelectedModels = !!listSection?.models.some((model) => {
      return !!this.selectedModelIdSet[model.id];
    });

    if (eventOpt?.shiftKey && listSectionHasOtherSelectedModels) {
      const lastSelectedIndex = listSection.models.findLastIndex((model) => {
        return !!this.selectedModelIdSet[model.id];
      });
      const currentSelectionIndex = listSection.models.findIndex((model) => {
        return model.id === modelId;
      });
      const isSelecting = !this.selectedModelIdSet[modelId];

      // Following gmail selection rules here
      if (isSelecting) {
        const modelsToSelect =
          currentSelectionIndex > lastSelectedIndex
            ? // select all models in between last and current
              listSection.models.slice(lastSelectedIndex, currentSelectionIndex + 1)
            : // we are selecting somewhere before last (doesn't matter where first is)
              // select all models between current and last
              listSection.models.slice(currentSelectionIndex, lastSelectedIndex);
        modelsToSelect.forEach((model) => {
          this.selectedModelIdSet[model.id] = true;
          this.getCellByModelId(model.id).setSelected(true);
        });
      } else {
        listSection.models
          // Deselect everything from the beginning to the cell being clicked
          .slice(0, currentSelectionIndex + 1)
          // Get only the selected models
          .filter((model) => {
            return !!this.selectedModelIdSet[model.id];
          })
          // Deselect them
          .forEach((model) => {
            delete this.selectedModelIdSet[model.id];
            this.getCellByModelId(model.id).setSelected(false);
          });
      }
    } else {
      if (this.selectedModelIdSet[modelId]) {
        delete this.selectedModelIdSet[modelId];
        cellView.setSelected(false);
      } else {
        this.selectedModelIdSet[modelId] = true;
        cellView.setSelected(true);
      }
    }
    this.trigger(app.ModelSelectorListView.Events.SelectionDidChange, this);
  },
});

app.ModelSelectorListView.Events = {
  SelectionDidChange: 'SelectionDidChange',
};
