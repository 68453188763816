import { MenuCoverPromoLinkType, MenuCoverPromoLinkTypeHelper, ModelType } from '@biteinc/enums';
import { menuCoverPromoSchema } from '@biteinc/schemas';

app.MenuCoverPromo = app.AbstractModel.extend({
  ModelName: 'menuCoverPromo',
  Schema: menuCoverPromoSchema,
  Type: ModelType.MenuCoverPromo,

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'linkType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuCoverPromoLinkType,
          nameGenerator: MenuCoverPromoLinkTypeHelper.name,
        });
    }
    return null;
  },
});
