import Backbone from 'backbone';
import _ from 'underscore';

import { OrderChannelHelper } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';

import { buildOrderChannelTag, i9nBadgesHtml } from '../helpers/html_builder';
import { template } from '../template';

const kOrgDropdownClass = 'org-dropdown';
const kSiteDropdownClass = 'site-dropdown';
const kLocationDropdownClass = 'location-dropdown';

app.HeaderView = Backbone.View.extend({
  tagName: 'header',
  id: 'header',
  className: 'no-user-select',
  template: template(
    // prettier-ignore
    `<span class="menu-button">${$('#hamburger-menu-icon-svg').html()}</span>` +
    `<a class="logo" alt="Bite" href="/"><span></span></a>` +
    `<div class="org-location-container"></div>`,
  ),
  _dropdownTemplate: template($('#header-dropdown-template').html()),
  _dropdownCount: 0,

  setMainTitle(title, alt, url) {
    this.$el.append(`<a class="org-name" alt="${alt}" href="${url}"><span>${title}</span></a>`);
  },

  addOrgDropdown(isCurrentLevel) {
    this.$('.org-name').remove();
    const orgGroup = {
      name: 'Your Brands',
      modelLinks: _.map(app.sessionUser.getOrgMemberships(), ({ orgName, orgNormalizedName }) => {
        return {
          altName: orgName,
          url: `/${orgNormalizedName}`,
          innerHtml: orgName,
        };
      }),
    };
    this._addDropdown({
      dropdownClass: kOrgDropdownClass,
      hierarchyLevel: 'Org',
      hierarchyLevelName: app.org.displayName(),
      modelGroups: [orgGroup],
      isCurrentLevel,
      showSearchBar: false,
    });
  },

  // This method unfortunately assumes that all the models have been loaded already
  addSiteDropdown(sites, siteName, isCurrentLevel) {
    const siteGroup = {
      name: 'Your Sites',
      modelLinks: sites.map((site) => {
        return {
          altName: site.get('name'),
          url: site.bureauUrl(),
          innerHtml: app.HtmlHelper.siteNameWithIntegrationsHtml(site),
          channelLinks: app.HtmlHelper.getSiteLocations(site).map((location) => {
            return {
              altName: OrderChannelHelper.nameCombiningKioskAndFlash(location.get('orderChannel')),
              url: location.bureauUrl(),
              innerHtml: buildOrderChannelTag(location.get('orderChannel'), {
                isLiveLocation: location.isLive(),
                isCanaryLocation: location.isCanary(),
                includeArrowIcon: true,
              }),
            };
          }),
        };
      }),
    };

    if (!this._dropdownCount) {
      // Move the org name to the container if we have a location dropdown
      // The CSS just works out that way
      this._$container.append(this.$('.org-name'));
    }

    this._$container.append('<div class="hierarchy-divider">&gt;</div>');
    this._addDropdown({
      dropdownClass: kSiteDropdownClass,
      hierarchyLevel: 'Site',
      hierarchyLevelName: siteName,
      modelGroups: [siteGroup],
      isCurrentLevel,
      showSearchBar: siteGroup.modelLinks.length > 3,
    });
  },

  // This method unfortunately assumes that all the models have been loaded already
  addLocationDropdown(locations, currentLocation) {
    const locationGroup = {
      name: 'Your Channels',
      modelLinks: locations.map((location) => {
        const channelTag = buildOrderChannelTag(location.get('orderChannel'), {
          isLiveLocation: location.isLive(),
          isCanaryLocation: location.isCanary(),
        });
        return {
          altName: OrderChannelHelper.nameCombiningKioskAndFlash(location.get('orderChannel')),
          url: location.bureauUrl(),
          innerHtml: `${channelTag}${i9nBadgesHtml(location.getAllI9nSchemas())}`,
        };
      }),
    };

    this._$container.append('<div class="hierarchy-divider">&gt;</div>');
    const channelName = OrderChannelHelper.nameCombiningKioskAndFlash(
      currentLocation.get('orderChannel'),
    );
    this._addDropdown({
      dropdownClass: kLocationDropdownClass,
      hierarchyLevel: 'Channel',
      hierarchyLevelName: channelName,
      modelGroups: [locationGroup],
      isCurrentLevel: true,
      showSearchBar: false,
    });
  },

  _addDropdown({
    dropdownClass,
    hierarchyLevel,
    hierarchyLevelName,
    modelGroups,
    isCurrentLevel,
    showSearchBar,
  }) {
    this._dropdownCount++;

    this._$container.append(
      this._dropdownTemplate({
        dropdownClass: `${dropdownClass} ${isCurrentLevel ? 'current-level' : ''}`,
        hierarchyLevel,
        hierarchyLevelName,
        modelGroups,
      }),
    );

    if (showSearchBar) {
      const $dropdownContainer = this._$container.find(`.${dropdownClass}`);
      const $list = $dropdownContainer.find('.dropdown-menu');

      function filterList(string) {
        const query = (string || '').trim().toLowerCase();
        $list.find('li.other-model').each(function (index) {
          let modelTotal = 0;
          const modelGroup = modelGroups.find(({ modelLinks }) => {
            if (index > modelLinks.length + modelTotal) {
              modelTotal += modelLinks.length;
            }
            return true;
          });
          const modelName = modelGroup.modelLinks[index - modelTotal].altName;
          $(this).toggle(modelName.toLowerCase().indexOf(query) >= 0);
        });
      }

      const placeholder = `search ${StringHelper.pluralize(hierarchyLevel).toLowerCase()}`;
      $list.prepend(`<li class="search"><input type="text" placeholder="${placeholder}"/></li>`);
      const $searchField = $list.find('li.search input');

      // Prevent the dropdown from hiding when user clicks on the search bar
      $searchField.click((e) => {
        e.stopPropagation();
      });

      $searchField.on('keyup', () => {
        filterList($searchField.val());
        return false;
      });

      $dropdownContainer[0].addEventListener('show.bs.dropdown', () => {
        filterList('');
      });

      $dropdownContainer[0].addEventListener('shown.bs.dropdown', () => {
        $searchField.focus();
      });
    }
  },

  updateLocationUrlsWithTabId(tabId) {
    [
      `.${kSiteDropdownClass} .other-model a.order-channel-link`,
      `.${kLocationDropdownClass} .other-model a`,
    ].forEach((locationButtonsSelector) => {
      this._$container.find(locationButtonsSelector).each((i, a) => {
        const $a = $(a);
        let url = $a.prop('href').split('#')[0];
        if (tabId) {
          // Take the tabId without the specified objectId (e.g. kioskId or orderId)
          url += `#${tabId.split('/')[0]}`;
        }
        $a.prop('href', url);
      });
    });
  },

  render() {
    this.$el.html(this.template());
    this._$container = this.$('.org-location-container');

    return this;
  },
});
