import _ from 'underscore';

import { Log } from '@biteinc/common';
import { ModelType, QuicksandRecordType } from '@biteinc/enums';

import { ModelHelper } from '../../helpers/model_helper';

app.QuicksandRecordCellView = app.CellView.extend({
  className() {
    return `${app.CellView.prototype.className.apply(this, arguments)} quicksand-record`;
  },

  render() {
    const self = this;
    app.CellView.prototype.render.apply(this, arguments);

    let parentType = null;
    const $dropdownOptions = [];
    const model = this.model.getModel();
    if (QuicksandRecordType.Create === this.model.get('type') && model) {
      switch (this.model.get('modelType')) {
        case ModelType.MenuItem: {
          if (model.getVisibleInPlaces().length || model.get('archivedAt')) {
            break;
          }

          parentType = 'Section';

          const sectionList = ModelHelper.getCollectionsForModelType(ModelType.MenuSection)[0];
          _.each(sectionList.models, (section) => {
            const $sectionItem = $(
              `<li class="item section-item"><a>${section.displayName()}</a></li>`,
            );
            $sectionItem.click(() => {
              section.addItem(model.id, () => {
                app.showSavedToast('Added to the end of the list!');
                self.render();
                model.trigger('change');
              });
            });
            $dropdownOptions.push($sectionItem);
          });
          break;
        }
        case ModelType.MenuSection: {
          const places = model.getVisibleInPlaces();
          if (places.length || model.get('archivedAt')) {
            break;
          }

          parentType = 'Page';
          const structureList = ModelHelper.getCollectionsForModelType(ModelType.MenuStructure)[0];
          _.each(structureList.models, (structure) => {
            const pages = structure.getAllPages();
            _.each(pages, (page) => {
              const $pageItem = $(`<li class="item page-item"><a>${page.name}</a></li>`);
              $pageItem.click(() => {
                page.sectionIds.push(model.id);
                structure.save(
                  {
                    pages: structure.attributes.pages,
                    sectionIds: structure.attributes.sectionIds,
                  },
                  {
                    error() {
                      Log.error('failed to save', model, 'in', structure);
                    },
                    success() {
                      window.app.showSavedToast();
                      self.render();
                      model.trigger('change', model);
                    },
                  },
                );
              });
              $dropdownOptions.push($pageItem);
            });
          });
          break;
        }
      }
    } else if (QuicksandRecordType.Update === this.model.get('type')) {
      const showButtonText = 'View Diff';
      const hideButtonText = 'Hide Diff';
      const $showDiffButton = $(
        '<button type="button" class="btn btn-secondary btn-sm view-diff" ' +
          `aria-label="${showButtonText}">${showButtonText}</button>`,
      );

      let hasRenderedJsonView = false;

      $showDiffButton.click((e) => {
        const $button = $(e.target);

        if (hasRenderedJsonView) {
          this.$('.name [alt="diff"]').toggle();
        } else {
          // Building the JSON views can be expensive, so building upto 50 at once can cause the
          // "POS Sync" tab to feel slow
          // To counter this, instead of rendering them with the rest of the quicksand record cell
          // view, only build them after the user clicks the "View Diff" button

          // remove '\' prefix from escaped reserved mongo keys like '/$set'
          const formattedDiff = {};
          _.each(this.model.get('diff'), (value, key) => {
            formattedDiff[key.replace(/^\\\$/, '$')] = value;
          });
          this.$('.name .json-view-container').append(
            new app.JsonView({
              jsonValue: formattedDiff,
              altText: 'diff',
            }).render().$el,
          );
          hasRenderedJsonView = true;
        }

        if ($button.text() === showButtonText) {
          $button.text(hideButtonText);
          $button.attr('aria-label', hideButtonText);
        } else {
          $button.text(showButtonText);
          $button.attr('aria-label', showButtonText);
        }
      });
      this.$buttonGroup.append($showDiffButton);
    }

    if (parentType && $dropdownOptions.length) {
      this.$buttonGroup.append(
        // prettier-ignore
        '<span class="cell-badge not-in-menu">not in menu</span>' +
        '<div class="btn-group">' +
          `<button type="button" class="btn btn-secondary btn-sm move-item" data-bs-toggle="dropdown" aria-label="Add to ${parentType}">` +
            `Add to ${parentType}` +
          '</button>' +
          '<ul class="dropdown-menu move-menu dropdown-menu-right"></ul>' +
        '</div>',
      );
      this.$buttonGroup.addClass('not-in-menu');
      this.$buttonGroup.find('.move-menu').append($dropdownOptions);
    }

    return this;
  },
});
