import type { Schema } from '@biteinc/common';

export const magicCopyOptionsSchema: Schema.Model = {
  displayName: 'Magic Copy Options',
  type: 'object',
  fields: {
    recommendationRules: {
      type: 'bool',
      displayName: 'Recommendation Rules',
      tooltip: {
        text: 'Destructive action to destroy all rules at target location and copy rules over',
        isWarning: true,
      },
    },
    // This field will be disabled if the source location has a pos i9n
    pricesAndTaxes: {
      type: 'bool',
      displayName: 'Prices and Taxes',
      tooltip: {
        text: 'Use with caution because prices and taxes are prone to change based on address. Only available for non-POS integrated locations.',
        isWarning: true,
      },
    },
    menuPagesAndSections: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Menus and Sections',
      tooltip: {
        text: "If sections aren't synced, then they will be recreated. Menus will be created.",
      },
    },
    dayParts: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Day Parts',
      tooltip: {
        text: 'Day parts will be created. Day parts will only be removed if "Menus and Sections" are also being copied.',
      },
    },
    compoundMenuItems: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Compound Menu Items',
      tooltip: {
        text: 'Existing ones will be entirely replaced with new copies from the source location.',
      },
    },
    menuItems: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Menu Items',
    },
    modsAndModGroups: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Mods and Mod Groups',
    },
    menuCovers: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Menu Covers',
    },
    badgesAndWords: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Badges and Dictionary Words',
    },
    featureSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Feature Settings',
      tooltip: {
        text: 'Show Guest Survey, Show Page Navigation, Intro Images, Closed Cover Images, Map Base Layer, UI for Tall Screens, Special Requests, Text Size picker',
      },
    },
    fulfillmentSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Fulfillment Settings (only if order channel matches)',
      tooltip: {
        text: 'Dining Options, Allow email receipts, Email Receipt Header Image',
      },
    },
    receiptSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Receipt Settings',
    },
    menuBehaviorSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Menu Behavior Settings',
      tooltip: {
        text: 'Timeout after checkout, Timeout after inactivity in menu, Prompt Before Closing Menu, Auto-Scroll the customization flow, Use traditional close button for popups, use side navigation in kiosk',
      },
    },
    paymentSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Payment Details',
      tooltip: {
        text: 'Min/Max Transaction Amount, Payment Terminal Idle Text, Allow paying with cash (if available), Allow tips (if available)',
      },
    },
    presentationSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Presentation Settings',
      tooltip: {
        text: 'Appearance, Default Text Size, Price Style, Show $ in front of prices, Footnotes, Custom Strings',
      },
    },
    languageSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Language Settings',
      tooltip: {
        text: 'Supported Languages, Translations',
      },
    },
    commonSettingsV2: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Common Settings',
      tooltip: {
        text: 'Brand Name, Nutrition Info, Hidden Items',
      },
    },
    kioskSettingsV2: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Kiosk Settings',
      tooltip: {
        text: 'Order Mode, Cover Images, ADA',
      },
    },
    flashSettingsV2: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Flash Settings',
      tooltip: {
        text: 'Custom account settings, disclaimers',
      },
    },
    recommendationSettings: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Recommendation Settings',
      tooltip: {
        text: 'Visibility Level for Per-Item Upsell, Show At-Checkout Upsell',
      },
    },
    recommendationSettingsV2: {
      type: 'bool',
      defaultValue: true,
      displayName: 'Recommendation Settings V2',
      tooltip: {
        text: 'Visibility Level for Per-Item Upsell, Show At-Checkout Upsell',
      },
    },
  },
};
