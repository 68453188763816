// cspell:ignore JaroWinkler

import { JaroWinklerDistance } from 'natural';

import type { Schema } from '@biteinc/common';
import {
  appearanceSchema,
  locationSchema,
  menuAppearanceSchema,
  orgSchema,
  settingsSchema,
} from '@biteinc/schemas';

import { siteSchema } from '../models/site_schema';

const schemasByAppContext: Record<string, Schema.ModelWithTabIds<string>[]> = {
  org: [orgSchema, settingsSchema, appearanceSchema as Schema.ModelWithTabIds<string>],
  site: [siteSchema, settingsSchema],
  location: [
    locationSchema,
    menuAppearanceSchema as Schema.ModelWithTabIds<string>,
    appearanceSchema as Schema.ModelWithTabIds<string>,
    settingsSchema,
  ],
};

export enum SearchContext {
  Org = 'org',
  Site = 'site',
  Location = 'location',
}

type MatchResult = {
  fieldKey: string;
  displayName: string;
  section: string;
  context: SearchContext;
  priority: number;
};

export module SchemaFieldSearchHelper {
  /**
   * Look above where we are for a setting that matches the search string
   * It doesn't make sense to look below as it would mean having to select the child site or channel
   */
  function getOtherContextsToSearch(context: SearchContext): SearchContext[] {
    switch (context) {
      case SearchContext.Org:
        return [];
      case SearchContext.Site:
        return [SearchContext.Org];
      case SearchContext.Location:
        return [SearchContext.Org, SearchContext.Site];
    }
  }

  function getSchemaPropertyMatchesForSearchString(
    dirtySearchString: string,
    schema: Schema.TypedObjectField | Schema.TypedObjectArrayField,
  ): string[] {
    const searchString = dirtySearchString.trim().toLowerCase();
    return Object.entries(schema.fields)
      .filter(([fieldName, fieldSchema]) => {
        // See if any child fields match
        if (
          fieldSchema.type === 'array' &&
          fieldSchema.elementType === 'object' &&
          'fields' in fieldSchema
        ) {
          return getSchemaPropertyMatchesForSearchString(searchString, fieldSchema).length > 0;
        }
        if (fieldSchema.type === 'object' && 'fields' in fieldSchema) {
          return getSchemaPropertyMatchesForSearchString(searchString, fieldSchema).length > 0;
        }

        const distanceForFieldKey = JaroWinklerDistance(fieldName, searchString, {
          ignoreCase: true,
        });
        const distanceForName = fieldSchema.displayName
          ? JaroWinklerDistance(fieldSchema.displayName, searchString, {
              ignoreCase: true,
            })
          : 0;
        // This is meant to catch small words like "page" or "slug"
        const containedInDisplayNameBetweenWordBoundaries =
          searchString.length >= 3 && fieldSchema.displayName
            ? new RegExp(`\\b${searchString}\\b`, 'i').test(fieldSchema.displayName)
            : false;
        // This is meant to catch partial expressions like "page nav" for "page navigation"
        const containedInDisplayName =
          searchString.length >= 7 && fieldSchema.displayName
            ? fieldSchema.displayName.toLowerCase().includes(searchString)
            : false;
        return (
          distanceForName > 0.8 ||
          distanceForFieldKey > 0.8 ||
          containedInDisplayNameBetweenWordBoundaries ||
          containedInDisplayName
        );
      })
      .map(([fieldName]) => fieldName);
  }

  /**
   * Some nested schema objects do not have a displayName, so we need to generate one
   */
  function getDisplayNameFromFieldKey(fieldKey: string): string {
    const words = fieldKey.split(/(?=[A-Z])/);
    return words.map((word) => word[0].toUpperCase() + word.slice(1)).join(' ');
  }

  function getPriorityFromContext(context: SearchContext): number {
    switch (context) {
      case SearchContext.Org:
        return 0;
      case SearchContext.Site:
        return 1;
      case SearchContext.Location:
        return 2;
    }
  }

  export function getSchemaFieldSections(
    context: SearchContext,
    searchString: string,
  ): MatchResult[] {
    const schemas = schemasByAppContext[context];
    const contextSections = schemas
      .map((schema) => {
        const properties = getSchemaPropertyMatchesForSearchString(searchString, schema);
        return properties.map((fieldKey) => {
          const displayName =
            schema.fields[fieldKey].displayName || getDisplayNameFromFieldKey(fieldKey);
          return {
            fieldKey,
            displayName,
            section: schema.fields[fieldKey].tabId,
            context,
            priority: getPriorityFromContext(context),
          };
        });
      })
      .flat();
    const otherContexts = getOtherContextsToSearch(context);
    const otherContextSections = otherContexts
      .map((otherContext) => getSchemaFieldSections(otherContext, searchString))
      .flat();
    return [...contextSections, ...otherContextSections].sort((a, b) => a.priority - b.priority);
  }
}
