import { DayPartHelper } from '@biteinc/business-rules';
import {
  allEnumValues,
  ModelType,
  OrderChannel,
  OrderChannelHelper,
  ReportScheduleScope,
  ReportScheduleScopeHelper,
  ReportType,
  ReportTypeHelper,
  Timezone,
  TimezoneHelper,
  Weekday,
  WeekdayHelper,
} from '@biteinc/enums';
import { reportScheduleSchema } from '@biteinc/schemas';

import { ReportHelper } from '../helpers/report_helper';

app.ReportSchedule = app.AbstractModel.extend({
  ModelName: 'reportSchedule',
  Schema: reportScheduleSchema,
  Type: ModelType.ReportSchedule,

  defaults() {
    let orderChannels = allEnumValues(OrderChannel);

    if (app.site && !app.location) {
      orderChannels = app.locationList
        .getLocationsWithSiteId(app.site.id)
        .filter((siteLocation) => {
          return !siteLocation.isBiteOnly();
        })
        .map((siteLocation) => {
          return siteLocation.get('orderChannel');
        });
    }

    return {
      scope: ReportScheduleScope.Daily,
      isEnabled: true,
      orderChannels,
    };
  },

  displayName() {
    let name = ReportScheduleScopeHelper.name(this.get('scope'));
    if (this.get('scope') !== ReportScheduleScope.Daily) {
      name += ` on ${WeekdayHelper.name(this.get('day'))}s`;
    }
    name += ' at ';
    name += DayPartHelper.displayDayTimestamp(this.get('time'));
    if (!this.get('isEnabled')) {
      name += ' (Disabled)';
    }
    return name;
  },

  canBeManaged() {
    if (!this.get('locationGroupId')) {
      return true;
    }

    const locationGroup = app.locationGroupList.find((locationGroup) => {
      return locationGroup.get('_id') === this.get('locationGroupId');
    });
    if (!locationGroup) {
      return false;
    }
    return locationGroup.get('sessionUserCanManage');
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'day':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: Weekday,
          nameGenerator: WeekdayHelper.name,
          sort: app.AbstractCollection.SortOptions.WORK_WEEKDAYS,
        });
      case 'scope':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ReportScheduleScope,
          nameGenerator: ReportScheduleScopeHelper.name,
        });
      case 'type': {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ReportType,
          nameGenerator: ReportTypeHelper.name,
          values: ReportHelper.getAvailableReportTypes(
            true,
            app.sessionUser,
            app.org,
            app.site,
            app.location,
            app.getSettingsFromContext(),
          ),
        });
      }
      case 'orderChannels': {
        let orderChannels = allEnumValues(OrderChannel);

        // Get channels only being used by locations in the site
        if (app.site && !app.location) {
          const siteLocations = app.locationList.getLocationsWithSiteId(app.site.id);
          orderChannels = siteLocations
            .filter((siteLocation) => {
              return !siteLocation.isBiteOnly();
            })
            .map((siteLocation) => {
              return siteLocation.get('orderChannel');
            });
        }

        return app.AbstractCollection.createFromTsEnum({
          tsEnum: OrderChannel,
          values: orderChannels,
          nameGenerator: OrderChannelHelper.nameCombiningKioskAndFlash,
        });
      }
      case 'locationGroupId':
        if (!this.canBeManaged()) {
          // If the user cannot manage this report schedule, then the user cannot change the
          // location group, so it's ok if we display a location group that the user cannot manage.
          // Return a single element list with the current location group.

          return new app.LocationGroupList(
            app.locationGroupList.where({ _id: this.get('locationGroupId') }),
          );
        }

        return app.LocationGroupList.newSelectableList(app.locationGroupList, {
          includeOrgWide: true,
          includeAllLive: true,
          includeSingleLocationGroups: true,
        });
      case 'salesByLocationGroupIds':
        if (!this.canBeManaged()) {
          // If the user cannot manage this report schedule, then the user cannot change the
          // location group, so it's ok if we display location groups that the user cannot manage.

          return new app.LocationGroupList(
            app.locationGroupList.filter((locationGroup) => {
              return this.get('salesByLocationGroupIds').includes(locationGroup.get('_id'));
            }),
          );
        }

        return app.LocationGroupList.newSelectableList(app.locationGroupList);
      case 'timezone':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: Timezone,
          nameGenerator: (timezone) => {
            const name = TimezoneHelper.name(timezone);
            // prepend timezones likes EST/CST with a space so they are sorted to the top
            return name === timezone ? timezone : ` ${name}`;
          },
        });
      case 'vendorId':
        return app.vendorList;
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'locationGroupId':
      case 'salesByLocationGroupIds': {
        const locationGroup = element;
        return locationGroup.displayNameAsListFieldElement(plainTextOnly);
      }
    }
    return displayName;
  },
});
