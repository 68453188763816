import _ from 'underscore';

import { DayPartHelper } from '@biteinc/business-rules';
import {
  BrinkApiServerHelper,
  CouponProvider,
  EigenApiServerHelper,
  GivexApiServerHelper,
  IntegrationInstanceTypeHelper,
  IntegrationSystem,
  LoyaltyAuthMethodHelper,
  OmnivorePosTypeHelper,
  PinataApiServerHelper,
} from '@biteinc/enums';

import { getBadgeHtml } from '../../helpers/html_builder';
import { TimeHelper } from '../../helpers/time_helper';

app.IntegrationCellView = app.CellView.extend({
  initialize() {
    app.CellView.prototype.initialize.apply(this, arguments);

    if (this.model.requiresOAuth() && !this.model.hasOAuthed()) {
      const $connectButton = this.addButtonWithClass(
        'btn-primary',
        this._connectButtonWasClicked.bind(this),
      );
      $connectButton.html(`Connect to ${this.model.displayName()}`);
    } else if (this.model.isMissingUserProvidedFields()) {
      const $editButton = this.addButtonWithClass(
        'btn-primary',
        this._editButtonWasClicked.bind(this),
      );
      $editButton.html('Add Credentials');
    } else if (
      this.model.requiresOAuth() &&
      this.model.hasOAuthed() &&
      this.model.canLoginToAccount()
    ) {
      const $viewAccountButton = this.addButtonWithClass(
        'btn-primary',
        this._viewAccountButtonWasClicked.bind(this),
      );
      $viewAccountButton.html(`View ${this.model.displayName()} Account`);
    }

    if (app.sessionUser.canManageFullI9ns() && this.model.canBeSynced() && !this.model.isPosI9n()) {
      const $syncButton = this.addButtonWithClass(
        'btn-primary',
        this._syncButtonWasClicked.bind(this),
      );

      $syncButton.html('<i class="bi bi-arrow-clockwise"></i>&nbsp;Sync');
    }

    if (
      app.sessionUser.isBite() &&
      this.model.canHaveOrgLevelWebhookSettings() &&
      !this.model.hasOrgLevelWebhookSettingsSetup()
    ) {
      const $setUpWebhookSettingsButton = this.addButtonWithClass(
        'btn-primary',
        this._setUpWebhooksButtonWasClicked.bind(this),
      );
      $setUpWebhookSettingsButton.html(`Set Up Webhooks`);
    }

    this.options.clickable = this.model.canBeManagedByUser();
    this.options.selectable = true;
  },

  _syncButtonWasClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Syncing', 'Synced');
    const i9n = this.model;
    i9n.syncI9n((err, response) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else if (response?.message) {
        new app.AlertView().show(response.message, null, () => {
          window.location.reload();
        });
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Synced!', i9n.isPosI9n());
        window.location.reload();
      }
    });
    return false;
  },

  _editButtonWasClicked() {
    this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
    const modal = new app.I9nCredentialsDetailsView({ model: this.model });
    app.modalManager.showModalWithView(modal);
    return false;
  },

  _connectButtonWasClicked(e) {
    const $button = $(e.target);
    $button.initLoadingButton($button.html(), 'Connecting');
    window.location = this.model.buildOAuthUrl();

    return false;
  },

  _setUpWebhooksButtonWasClicked() {
    window.location = `/${app.org.get('normalizedName')}/#org-settings`;
  },

  _viewAccountButtonWasClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Connecting');
    const i9n = this.model;
    i9n.getLoginLink((err, response) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        window.open(response.url, '_blank');
      }
    });

    return false;
  },

  _getOrderStatusUpdateMethod(i9n, i9nSchema) {
    if (!i9nSchema.supportsOrderStatusPolling && !i9nSchema.supportsOrderStatusWebhooks) {
      throw new Error('schema does not support order status updates');
    }
    if (!i9n.get('notifyUserOfOrderStatusUpdates')) {
      return 'NO';
    }

    if (
      !i9nSchema.supportsOrderStatusPolling ||
      (i9nSchema.supportsOrderStatusWebhooks && i9n.get('useWebhooksForOrderStatuses'))
    ) {
      return 'Webhooks';
    }

    return 'Polling';
  },

  render() {
    app.CellView.prototype.render.apply(this, arguments);

    const i9n = this.model;
    const i9nSchema = i9n.getI9nSchema();
    const neededActions = i9n.getNeededActions();
    let i9nStatus = null;
    if (this.model.requiresOAuth() && this.model.hasOAuthed()) {
      i9nStatus = `Connected to ${i9n.displayName()}`;
    }
    let nameHtml = `${getBadgeHtml(i9n.getI9nSchema())}&nbsp;${i9n.displayName()}`;

    const infoParts = [];
    const locationName = i9n.getLocationName();
    if (locationName) {
      infoParts.push(`location: ${locationName}`);
    }

    switch (i9n.get('system')) {
      case IntegrationSystem.Omnivore:
        infoParts.push(`locationID: ${i9n.get('locationID')}`);
        infoParts.push(OmnivorePosTypeHelper.name(i9n.get('posType')));
        break;
      case IntegrationSystem.Olo:
        infoParts.push(`locationID: ${i9n.get('locationID')}`);
        break;
      case IntegrationSystem.Punchh:
        // For Flash, since the auth method on kiosk and kiosk-preview is barcode which isn't
        // possible on flash.
        if (i9n.get('flashAuthMethod')) {
          infoParts.push(LoyaltyAuthMethodHelper.name(i9n.get('flashAuthMethod')));
        }
        if (i9n.get('enableAuthedLoyalty')) {
          infoParts.push('Authed Loyalty Enabled');
        }
        break;
      case IntegrationSystem.Smg:
        infoParts.push(`storeID: ${i9n.get('storeID')}`);
        break;
      case IntegrationSystem.Revel:
        infoParts.push(`Establishment ID: ${i9n.get('establishmentId')}`);
        if (i9n.hasArr('menuIds')) {
          const menuNames = i9n.get('menuIds').map((menuId) => {
            return i9n.getSyncedProp('menuById')[menuId]?.name || 'Unknown Menu';
          });
          infoParts.push(`Menus: ${_.sortBy(menuNames).join(', ')}`);
        }
        break;
      case IntegrationSystem.CardConnect:
        infoParts.push(`MID: ${i9n.get('merchantId')}`);
        break;
      case IntegrationSystem.Tcc:
        infoParts.push(`ClientId: ${i9n.get('clientId')}`);
        break;
      case IntegrationSystem.PaytronixOlo:
        infoParts.push(`Merchant ID: ${i9n.get('merchantID')}`);
        infoParts.push(LoyaltyAuthMethodHelper.name(i9n.get('authMethod')));
        break;
      case IntegrationSystem.Worldpay:
        infoParts.push(`Account ID: ${i9n.get('accountId')}`);
        infoParts.push(`Debit enabled: ${i9n.get('enableDebitSupport')}`);
        break;
      case IntegrationSystem.RitualDeprecated:
        infoParts.push(`Merchant ID: ${i9n.get('merchantId')}`);
        break;
      case IntegrationSystem.Givex:
        infoParts.push(GivexApiServerHelper.name(i9n.get('apiServer')));
        break;
      case IntegrationSystem.PinataPos:
      case IntegrationSystem.PinataLoyalty:
      case IntegrationSystem.PinataStoredValue:
      case IntegrationSystem.PinataNotifier:
      case IntegrationSystem.PinataFulfillment:
        infoParts.push(PinataApiServerHelper.name(i9n.get('apiServer')));
        break;
      case IntegrationSystem.ParBrink:
        infoParts.push(BrinkApiServerHelper.name(i9n.get('apiServer')));
        infoParts.push(`locationID: ${i9n.get('locationToken')}`);
        if (i9n.get('alternateMenuId')) {
          const { name } = (i9n.getSyncedProp('menuById') || {})[i9n.get('alternateMenuId')] || {};
          infoParts.push(`Menu: ${name}`);
        }
        break;
      case IntegrationSystem.Eigen:
        infoParts.push(EigenApiServerHelper.name(i9n.get('apiServer')));
        break;
      case IntegrationSystem.ParBrinkLoyalty:
        infoParts.push(`groupToken: ${i9n.get('groupToken')}`);
        break;
      case IntegrationSystem.PunchhOlo:
        infoParts.push(LoyaltyAuthMethodHelper.name(i9n.get('authMethod')));
        break;
      case IntegrationSystem.Heartland:
        infoParts.push(`Site ID: ${i9n.get('heartlandSiteId')}`);
        break;
      case IntegrationSystem.Stripe:
      case IntegrationSystem.StripeTerminal:
        if (this.model.requiresOAuth() && this.model.hasOAuthed()) {
          infoParts.push(`Account: ${this.model.getAuthData().stripeAccountId}`);
        }
        break;
      case IntegrationSystem.Elavon:
        ['terminalId', 'locationName', 'chainCode'].forEach((fieldName) => {
          infoParts.push(`${i9nSchema.fields[fieldName].displayName}: ${i9n.get(fieldName)}`);
        });
        break;
      case IntegrationSystem.Toast:
        if (i9n.hasArr('menuIds')) {
          const menuNames = i9n.get('menuIds').map((menuId) => {
            return i9n.getSyncedProp('menuById')[menuId]?.name || 'Unknown Menu';
          });
          infoParts.push(`Menus: ${_.sortBy(menuNames).join(', ')}`);
        }
        break;
      case IntegrationSystem.ChoptLoyalty:
        infoParts.push(`locationID: ${i9n.get('choptLocationID')}`);
        break;
    }
    if (i9n.has('instanceType')) {
      infoParts.push(IntegrationInstanceTypeHelper.name(i9n.get('instanceType')));
    }
    if (i9nSchema.supportsOrderStatusPolling || i9nSchema.supportsOrderStatusWebhooks) {
      infoParts.push(`Order status updates: ${this._getOrderStatusUpdateMethod(i9n, i9nSchema)}`);
    }

    if (
      (app.location.get('couponProvider') === CouponProvider.Loyalty &&
        i9n.getI9nSchema().type === 'loyalty') ||
      (app.location.get('couponProvider') === CouponProvider.Pos &&
        i9n.getI9nSchema().type === 'pos')
    ) {
      infoParts.push('Used for coupons');
    }
    if (infoParts.length) {
      nameHtml += ` (${infoParts.join(', ')})`;
    }

    if (i9nStatus || neededActions.length) {
      nameHtml += '<br /><br />';
      if (neededActions.length) {
        nameHtml += `<span class="warning">${neededActions.join('<br />')}</span>`;
      } else {
        nameHtml += i9nStatus;
      }
    }

    this.$('.name').html(nameHtml);

    const $cellRow = $('<div class="cell-row"></div>');
    const $div = $('<div style="padding: 0 10px;"></div>');
    $cellRow.append($div);
    this.$el.append($cellRow);
    if (i9n.canBeSynced()) {
      const syncInfo = i9n.get('syncInfo') || {};
      let syncMessage = 'Never synced';
      if (syncInfo.syncedAt) {
        const timestamp = TimeHelper.displayDateFromTimestamp(
          syncInfo.syncedAt,
          app.location.get('timezone'),
        );
        syncMessage = `Last synced on: ${timestamp}`;
      }
      $div.append(`<p>${syncMessage}</p>`);
      if (syncInfo.automaticSyncIncidentLastError) {
        const { description, debugMessage, mdCode } = syncInfo.automaticSyncIncidentLastError;
        const errorMessage = debugMessage ?? `${description} (${mdCode})`;
        $div.append(`<span class="warning">Sync Incident:<br />${errorMessage}</span>`);
      }
    }

    if (i9n.isTwilio() && app.sessionUser.canManageSomeI9ns()) {
      let twilioDetails = 'Sender Phone Number: Unset';
      const senderPhoneNumberId = i9n.get('senderPhoneNumberId');

      if (senderPhoneNumberId) {
        const phoneNumberById = i9n.getSyncedProp('phoneNumberById');
        if (phoneNumberById) {
          const phoneNumberData = phoneNumberById[senderPhoneNumberId];
          if (phoneNumberData && phoneNumberData.phoneNumber) {
            twilioDetails = `Sender Phone Number: ${phoneNumberData.phoneNumber}`;
          }
        }
      }

      $div.append(`<p>${twilioDetails}</p>`);
    }

    const details = [];
    if (i9nSchema.type === 'pos') {
      details.push(`Sync sections: ${!!i9n.get('syncSections')}`);
      details.push(`Supports tips: ${!!i9n.supportsTips()}`);
      details.push(`Supports Item Recipient: ${!!i9n.supportsItemRecipient()}`);
      const partialI9n = app.location.get('integrationById')[i9n.id];
      const locationSyncedData = (partialI9n || {}).syncedData || {};
      if (_.has(locationSyncedData, 'specialRequestMaxLength')) {
        details.push(`Special request max length: ${locationSyncedData.specialRequestMaxLength}`);
      }
    } else if (i9nSchema.type === 'loyalty') {
      details.push(`Send order without auth: ${!!i9n.get('sendOrderWithoutAuth')}`);
    }
    if (details.length) {
      $div.append(`<pre>${details.join('\n')}</pre>`);
    }

    if (i9n.getSyncedProp('dayParts')?.length) {
      const dayParts = i9n.getSyncedProp('dayParts').map((dayPartData) => {
        return DayPartHelper.displayDayPartData(dayPartData);
      });
      $div.append(`<pre alt="Day Parts">${dayParts.join('\n\n')}</pre>`);
    }
    if (i9n.getSyncedProp('unmappedOpenHoursTimetables')?.length) {
      const unmappedOpenHoursTimetables = i9n
        .getSyncedProp('unmappedOpenHoursTimetables')
        .map(({ posId, daySchedules }) => {
          return DayPartHelper.displayOpenHoursTimetableData({
            posId,
            daySchedules: daySchedules ?? [],
          });
        });
      $div.append(
        `<pre alt="Unmapped Open Hours">${unmappedOpenHoursTimetables.join('\n\n')}</pre>`,
      );
    }
    if (i9n.getSyncedProp('unmappedOpenHoursOverrides')?.length) {
      const overrides = i9n.getSyncedProp('unmappedOpenHoursOverrides').map((openHoursOverride) => {
        return DayPartHelper.displayOpenHoursOverrideData(
          openHoursOverride,
          app.location.get('timezone'),
        );
      });
      $div.append(`<pre alt="Unmapped Open Hour Overrides">${overrides.join('\n\n')}</pre>`);
    }

    if (i9n.hasStr('notes')) {
      $div.append(`<p>Notes: ${i9n.get('notes').split('\n').join('<br>')}</p>`);
    }

    return this;
  },
});
