app.BiteLogListView = app.PaginatedListView.extend({
  initialize(opts) {
    const options = {
      fetchOnInit: false,
      canCollapse: true,
      isReadOnly: true,
      paginateToLoadSelectedItem: true,
      cellOptions: {
        clickable: true,
      },
      cellView: app.BiteLogCellView,
      useVList: true,
      hideStatusButton: false,
      showTimestampsInLocationTimezone: true,
      filterSchemaButton: true,
      filterSchema: {
        fields: {
          fromTimestamp: {
            type: 'timestamp',
            displayName: 'From',
            allowFutureDates: false,
            allowPastDates: true,
            useSiteTimezone: !!app.site,
          },
          toTimestamp: {
            type: 'timestamp',
            displayName: 'To',
            allowFutureDates: false,
            allowPastDates: true,
            useSiteTimezone: !!app.site,
          },
          ...(app.location && {
            orderId: {
              type: 'mongoId',
              displayName: 'Order ID',
            },
            kioskId: {
              type: 'mongoId',
              displayName: 'Kiosk ID',
            },
            kioskName: {
              type: 'keyString',
              displayName: 'Kiosk Name',
              tooltip: {
                text: 'Case Insensitive Exact Matching ("A" will not return results if the full name is "Kiosk A")',
              },
            },
          }),
        },
      },
      ...(opts || {}),
    };
    app.PaginatedListView.prototype.initialize.apply(this, [options]);
  },

  __cellWasClicked(cellView, _eventOpt) {
    const biteLog = cellView.model;
    const detailsView = new app.BiteLogView({
      model: biteLog,
    });
    this.showDetailsView(detailsView);
  },

  __navigateToModel(log) {
    this.scrollToItemWithId(log.id, true);
  },
});
