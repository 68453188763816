app.MenuPagesTabView = app.TabView.extend({
  initialize() {
    app.TabView.prototype.initialize.apply(this, arguments);

    this.listenTo(app.menuSectionList, 'reset', this._sectionListDidReset);
    this.listenTo(this.model, 'change', this._menuStructureDidChange);

    if (app.menuSectionList.hasBeenFetched()) {
      this.pageView = new app.MenuPageView({
        model: this.model,
        page: this.model.attributes,
        level: 0,
        tabView: this,
      });
      this.setLeftView(this.pageView);
    }

    this.kioskPreviewView = new app.KioskPreviewView({
      forcedMenuStructureId: this.model.get('_id'),
      previewName: this.model.get('name'),
      showFlashDisclaimer: true,
      fulfillmentMethods: this.model.get('fulfillmentMethods') || [],
    });
    this.setRightView(this.kioskPreviewView);
  },

  supportsViewportMode: false,

  _sectionListDidReset() {
    if (!this.pageView) {
      this.pageView = new app.MenuPageView({
        model: this.model,
        page: this.model.attributes,
        level: 0,
        tabView: this,
      });
      this.setLeftView(this.pageView);
    }
    this.render();
  },

  _menuStructureDidChange() {
    if (this.pageView) {
      this.pageView.setPage(this.model.attributes, /* propagate */ true);
      this.kioskPreviewView.setFulfillmentMethods(this.model.get('fulfillmentMethods'));
    }
  },

  render() {
    const self = this;

    app.TabView.prototype.render.apply(this, arguments);

    if (this.pageView) {
      // Necessary because MenuPageView has some fieldGroups with upload
      // views.
      setTimeout(() => {
        self.pageView.viewWasAddedToDom();
      }, 1);
    }
  },
});
