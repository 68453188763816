import { Loader } from '@googlemaps/js-api-loader';
import Backbone from 'backbone';

import { Log } from '@biteinc/common';

import { i9nBadgesHtml } from '../helpers/html_builder';

app.KioskMapView = Backbone.View.extend({
  className: 'kiosk-map-view',

  initialize() {
    Backbone.View.prototype.initialize.apply(this, arguments);
  },

  setLocationsToDisplay(locations) {
    const locationIdSet = new Set(locations.map((location) => location.id.toString()));
    Object.keys(this.locationMarkerById).forEach((locationId) => {
      if (!locationIdSet.has(locationId)) {
        this.locationMarkerById[locationId].setMap(null);
      } else {
        this.locationMarkerById[locationId].setMap(this.map);
      }
    });
  },

  render() {
    this.locationMarkerById = {};
    this.$el.html(`
    <div>
      <div class="search-field">
        <div class="form-group">
          <div class="input-container">
            <input id="place-input" type="search" class="form-control search" placeholder="search for a location" />
          </div>
        </div>
      </div>

      <div id="map"/>
    </div>`);

    this.$el.find('#map').css({
      width: '100%',
      height: '100%',
    });

    const loader = new Loader({
      apiKey: app.data.GOOGLE_MAPS_AND_PLACES_API_KEY,
      version: 'weekly',
    });

    const mapLoader = loader.importLibrary('maps');
    const markerLoader = loader.importLibrary('marker');
    const placesLoader = loader.importLibrary('places');
    const coreLoader = loader.importLibrary('core');

    Promise.all([mapLoader, markerLoader, placesLoader, coreLoader])
      .then(async ([mapsLibrary, markersLibrary, placesLibrary, coreLibrary]) => {
        const { Map, InfoWindow, MapTypeId } = mapsLibrary;
        const { Marker } = markersLibrary;
        const { SearchBox } = placesLibrary;
        const { ControlPosition, LatLngBounds, Size, Point } = coreLibrary;

        const map = new Map(document.getElementById('map'), {
          zoom: 4,
          mapTypeId: MapTypeId.ROADMAP,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: [
            {
              featureType: 'administrative',
              elementType: 'all',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  lightness: 33,
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'all',
              stylers: [
                {
                  color: '#f7f7f7',
                },
              ],
            },
            {
              featureType: 'poi.business',
              elementType: 'all',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#deecdb',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  lightness: '25',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'all',
              stylers: [
                {
                  lightness: '25',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels',
              stylers: [
                {
                  saturation: '-90',
                },
                {
                  lightness: '25',
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'all',
              stylers: [
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'road.local',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'transit.line',
              elementType: 'all',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit.station',
              elementType: 'all',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'all',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#e0f1f9',
                },
              ],
            },
          ],
          // https://en.wikipedia.org/wiki/Geographic_center_of_the_United_States
          center: {
            lat: 39.8283,
            lng: -98.5795,
          },
        });
        this.map = map;

        const input = document.getElementById('place-input');
        input.style.margin = '20px';
        input.style.width = 'calc(60% - 40px)';
        const searchBox = new SearchBox(input);

        map.controls[ControlPosition.TOP_LEFT].push(input);
        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });

        let markersSBA = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (places.length === 0) {
            return;
          }

          // Clear out the old markers.
          markersSBA.forEach((marker) => {
            marker.setMap(null);
          });
          markersSBA = [];

          // For each place, get the icon, name and location.
          const bounds = new LatLngBounds();

          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              Log.error('Returned place contains no geometry');
              return;
            }

            const icon = {
              url: place.icon,
              size: new Size(71, 71),
              origin: new Point(0, 0),
              anchor: new Point(17, 34),
              scaledSize: new Size(25, 25),
            };

            // Create a marker for each place.
            markersSBA.push(
              new Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location,
              }),
            );
            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        });

        let currentInfoWindow = null;

        app.orgList.getAllLiveLocations().forEach((location) => {
          if (!location.attributes.coordinates) {
            return;
          }

          const marker = new Marker({
            position: {
              lat: location.attributes.coordinates[1],
              lng: location.attributes.coordinates[0],
            },
            map,
            title: location.attributes.name,
            visible: true,
            icon: {
              url: 'https://assets.getbite.com/images-default/hypeboard/pin-orange.png',
              scaledSize: new Size(15, 30),
              anchor: new Point(15, 48),
            },
          });

          this.locationMarkerById[location.id.toString()] = marker;

          const infowindow = new InfoWindow({});

          // close the info window if the map is clicked
          map.addListener('click', () => {
            if (currentInfoWindow) {
              currentInfoWindow.close();
            }
          });

          marker.addListener('click', () => {
            if (currentInfoWindow) {
              currentInfoWindow.close();
            }
            currentInfoWindow = infowindow;

            infowindow.setContent(
              `<div class="kiosk-map-info-window">
                <div class="kiosk-map-info-window-org">${app.orgList
                  .get(location.attributes.orgId)
                  .displayName()}</div>
                <div class='icon-row'>${i9nBadgesHtml(location.getAllI9nSchemas(), '')}</div>
                <div class="kiosk-map-info-window-name"><a href=${location.bureauUrl()} target="_blank"> ${location.nameWithChannel()}</a></div>
              </div>`,
            );
            infowindow.open(map, marker);
          });
        });
      })
      .catch((err) => {
        Log.error('google maps err', err);
      });

    return this;
  },
});
