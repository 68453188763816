import { OrderChannelHelper } from '@biteinc/enums';

app.KioskTabView = app.TabView.extend({
  supportsElementRouting: true,

  initialize(options) {
    app.TabView.prototype.initialize.apply(this, [options]);

    const collections = [app.kioskList, app.expoPrinterList];
    if (app.sessionUser.isBite()) {
      collections.push(app.kioskStatusList);
    }
    const dataSource = new app.DataSource([], { collections });
    const kioskListView = new app.KioskListView({
      collection: dataSource,
      detailsView: app.KioskDetailsView,
      cellView: app.KioskCellView,
      searchEnabled: false,
      fetchOnInit: false,
      canCreate:
        app.sessionUser.canManageKiosks() &&
        OrderChannelHelper.isKiosk(app.location.get('orderChannel')),
      updateUrlWithSelectedModel: (selectedModelId) => {
        if (selectedModelId) {
          // update the url only for kiosks and not untracked device statuses
          return !!app.kioskList.get(selectedModelId);
        }
        return true;
      },
    });
    this.setLeftView(kioskListView);
  },
});
