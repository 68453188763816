import _ from 'underscore';

import { LocationGroupDesignation, ModelType } from '@biteinc/enums';
import { locationGroupSchema } from '@biteinc/schemas';

import { i9nBadgesHtml } from '../helpers/html_builder';

app.LocationGroup = app.AbstractModel.extend({
  ModelName: 'locationGroup',
  Schema: locationGroupSchema,
  Type: ModelType.LocationGroup,

  isOrgWide() {
    return this.get('designation') === LocationGroupDesignation.OrgWide;
  },

  isAllLive() {
    return this.get('designation') === LocationGroupDesignation.AllLive;
  },

  isSingleLocationGroup() {
    return this.get('designation') === LocationGroupDesignation.SingleLocation;
  },

  isManuallyCreated() {
    return this.get('designation') === LocationGroupDesignation.ManuallyCreated;
  },

  canBeManaged() {
    // If the location group is new, then the user is currently trying to make it.
    // Surely a user can manage a location group they are in the process of creating.
    return this.isNew() || this.get('sessionUserCanManage');
  },

  displayNameAsListFieldElement(plainTextOnly) {
    if (this.isOrgWide()) {
      return `All Locations at ${app.org.displayName()}`;
    }
    if (this.isAllLive()) {
      return `All Live Locations at ${app.org.displayName()}`;
    }
    if (this.isSingleLocationGroup()) {
      const location = app.locationList.get(this.get('locationIds')[0]);
      return plainTextOnly
        ? location.nameWithChannel()
        : `➤ ${location.nameWithChannel()}${i9nBadgesHtml(location.getAllI9nSchemas())}`;
    }
    return `Group: ${this.displayName()}`;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'locationIds': {
        const location = element;
        return plainTextOnly
          ? location.nameWithChannel()
          : `${location.nameWithChannel()}${i9nBadgesHtml(location.getAllI9nSchemas())}`;
      }
    }
    return displayName;
  },

  getFieldCollection: (field /* subProperty, includeAllValues */) => {
    switch (field) {
      case 'locationIds': {
        const allowedLocations = _.filter(app.locationList.models, (location) => {
          return !location.isBiteOnly();
        });
        return new app.LocationList(allowedLocations);
      }
    }
    return null;
  },
});
