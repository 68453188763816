import {
  CustomerAccountsUsageHelper,
  KioskOrderingModeHelper,
  LocationRecommendationsLevelHelper,
  MenuModifierPriceDisplayStyleHelper,
  MenuPriceStyleHelper,
  RecommendationsFirstLoadVisibilityHelper,
  SimpleLoyaltySignupModeHelper,
} from '@biteinc/enums';

app.BiteSettingsFieldInheritanceView = app.ModallableView.extend({
  className() {
    return 'settings-field-inheritance-view';
  },

  initialize(options) {
    this.model = options.model;

    this.schema = options.schema;

    this.field = options.field;

    const propertySchema = this.schema.fields[this.field];
    const displayName = propertySchema.displayName;
    options.title = `${displayName} (${this.field}) inheritance state`;

    options.views = [];

    app.ModallableView.prototype.initialize.apply(this, arguments);
  },

  viewWasRemovedFromDom() {
    // This view is partly used as a details view, so to ensure that it gets cleaned up properly we
    // need to emit this event
    this.trigger(app.BaseDetailsView.Events.BaseDetailsViewDidClose, this);
  },

  _getValueFromEnum(propertyName, value) {
    switch (propertyName) {
      case 'kioskOrderingMode':
        return KioskOrderingModeHelper.name(value);
      case 'customerAccountsUsage':
        return CustomerAccountsUsageHelper.name(value);
      case 'simpleLoyaltySignupMode':
        return SimpleLoyaltySignupModeHelper.name(value);
      case 'priceStyle':
        return MenuPriceStyleHelper.name(value);
      case 'modifierPriceDisplayStyle':
        return MenuModifierPriceDisplayStyleHelper.name(value);
      case 'recommendationsLevel':
        return LocationRecommendationsLevelHelper.name(value);
      case 'recommendationsFirstLoadVisibility':
      case 'recommendationsInMenuVisibility':
        return RecommendationsFirstLoadVisibilityHelper.name(value);
      default:
        return value;
    }
  },

  _getFileNameFromUrl(url) {
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    return fileName;
  },

  _getValueFromType(propertyName, value) {
    const propertySchema = this.schema.fields[propertyName];
    if ([undefined, null].includes(value) && propertySchema.type !== 'bool') {
      return 'Unset';
    }
    switch (propertySchema.type) {
      case 'bool':
        return value ? 'Enabled' : 'Disabled';
      case 'int':
        return this._getValueFromEnum(propertyName, value);
      case 'array': {
        if (!value) {
          return 'Unset';
        }
        if (!value.length) {
          return 'Empty';
        }
        if (propertySchema.elementSubtype === 'Image') {
          return this._getFileNameFromUrl(value[0].url);
        }
        return value.join(', ');
      }
      case 'shortString':
      case 'keyString': {
        if (value === '') {
          return 'Empty String';
        }
        return this._getValueFromEnum(propertyName, value);
      }
      case 'price':
        return (value * 0.1).toLocaleString('en-US'); // 2,000.00
      default:
        return value;
    }
  },

  _getAllSiteLocationSettings(fieldKey) {
    return app.allSiteSettings.map((siteSetting) => {
      const site = app.sitesById[siteSetting.get('siteId')];
      const isOverridden = siteSetting.get('overrideSet').includes(fieldKey);
      return `<div style="border-bottom: 1px solid #B7B7B7">${
        site?.name || siteSetting.get('siteId')
      } <span style="float: right; ${isOverridden ? 'color: red' : ''}">${this._getValueFromType(
        fieldKey,
        siteSetting.get(fieldKey),
      )}</span></div>`;
    });
  },

  _getAllLocationSettings(fieldKey, exclusionList = []) {
    return app.allLocationSettings
      .filter((locationSetting) => {
        if (exclusionList.includes(locationSetting.get('siteId'))) {
          return false;
        }
        return true;
      })
      .map((locationSetting) => {
        const location = app.locationsById[locationSetting.get('locationId')];
        const locationInfo = location
          ? `${location.name} <i>(${location.orderChannel})</i>`
          : locationSetting.get('locationId');
        const isOverridden = locationSetting.get('overrideSet').includes(fieldKey);
        return `<div style="border-bottom: 1px solid #B7B7B7">${locationInfo} <span style="float: right; ${isOverridden ? 'color: red' : ''}">${this._getValueFromType(
          fieldKey,
          locationSetting.get(fieldKey),
        )}</span></div>`;
      });
  },

  _generateOrgTooltipText(fieldKey) {
    const orgValue = this.model.get(fieldKey);
    const allSiteSettings = this._getAllSiteLocationSettings(fieldKey);
    // We don't need to show the location settings for sites that override the field
    // Get all the siteIds that override the field
    const exclusionList = app.allSiteSettings
      .filter((siteSetting) => {
        return siteSetting.get('overrideSet').includes(fieldKey);
      })
      .map((siteSetting) => siteSetting.get('siteId'));
    const allLocationSettings = this._getAllLocationSettings(fieldKey, exclusionList);

    const parts = [];
    if (!allSiteSettings.length) {
      parts.push(`<div>All sites override their org's setting</div>`);
    } else {
      parts.push(...['<br><div><b>Sites</b><div>', ...allSiteSettings]);
    }

    if (!allLocationSettings.length) {
      parts.push(`<div>Check site level settings for channels overrides<div>`);
    } else {
      parts.push(...['<br><div><b>Channels</b><div>', ...allLocationSettings]);
    }
    return [`<div>Org: ${this._getValueFromType(fieldKey, orgValue)}</div>`, ...parts].join('');
  },

  _generateSiteTooltipText(fieldKey) {
    const allLocationSettings = this._getAllLocationSettings(fieldKey);
    const parts = [];
    if (!allLocationSettings.length) {
      parts.push(`<div>All channels override their site's setting<div>`);
    } else {
      parts.push(...['<br><div><b>Channels</b><div>', ...allLocationSettings]);
    }
    return parts.join('');
  },

  _generateTooltipText(fieldKey) {
    const scope = this.model.attributes.scope;
    if (scope === 'org') {
      return this._generateOrgTooltipText(fieldKey);
    }
    if (scope === 'site') {
      const orgSetting = this._getValueFromType(fieldKey, app.orgSettings.get(fieldKey));
      const siteSetting = this._getValueFromType(fieldKey, this.model.get(fieldKey));
      return `Org: ${orgSetting}<br> Site: ${siteSetting}<br>${this._generateSiteTooltipText(
        fieldKey,
      )}`;
    }
    if (scope === 'channel') {
      const orgSetting = this._getValueFromType(fieldKey, app.orgSettings.get(fieldKey));
      const siteSetting = this._getValueFromType(fieldKey, app.siteSettings.get(fieldKey));
      const channelSetting = this._getValueFromType(fieldKey, this.model.get(fieldKey));
      return `Org: ${orgSetting}<br>Site: ${siteSetting}<br>Channel: ${channelSetting}`;
    }
    return null;
  },

  render() {
    app.ModallableView.prototype.render.apply(this, arguments);

    this.$el.append(`<div>${this._generateTooltipText(this._options.field)}</div>`);

    return this;
  },
});
