import _ from 'underscore';

import { ModelType } from '@biteinc/enums';

app.showModelSelector = (
  { modalTitle, modelType, modelListView, maxSelectable, allowEmpty },
  callback,
) => {
  const showModelCounter = maxSelectable !== 1;
  let $modelCounter = null;

  let hasFinished = false;
  let modal = null;

  function saveSelection() {
    app.modalManager.hideModal(modal);
    if (!hasFinished) {
      setTimeout(() => {
        callback(null, _.keys(modelListView.selectedModelIdSet));
      }, 1000);
    }
  }

  const modallableView = new app.ModallableView({
    views: [modelListView],
    title: modalTitle,
    primaryFooterButton: {
      title: 'Next...',
      isEnabled: false,
      callback: saveSelection,
    },
  });

  const event = app.ModelSelectorListView.Events.SelectionDidChange;
  modallableView.listenTo(modelListView, event, (listView) => {
    const modelCount = _.size(listView.selectedModelIdSet);
    if (maxSelectable === 1 && modelCount === maxSelectable) {
      saveSelection();
      hasFinished = true;
    } else {
      if ($modelCounter) {
        $modelCounter.html(`${modelCount} ${modelType}${modelCount === 1 ? '' : 's'} selected`);
      }
      modal.footer.setSaveButtonEnabled(allowEmpty ? true : modelCount > 0);
    }
  });

  modal = app.modalManager.showModalWithView(modallableView);

  if (showModelCounter) {
    $modelCounter = modal.footer.addLabel('model-counter', true);
  }

  return { modal, modelListView };
};

app.showLocationSelector = (
  { modalTitle, locationsUrl, locationGroupsUrl, maxSelectable, liveFirst, allowEmpty },
  callback,
) => {
  const locationList = new app.LocationList(null, { url: locationsUrl });

  const collections = [];
  if (locationGroupsUrl) {
    collections.push(new app.LocationGroupList(null, { url: locationGroupsUrl }));
  }
  collections.push(locationList);

  const locationListView = new app.LocationSelectorListView({
    collection: new app.DataSource(null, {
      collections,
      mainCollection: locationList,
    }),
    cellOptions: {
      getName(locationOrLocationGroup) {
        if (locationOrLocationGroup.Type === ModelType.Location) {
          return app.HtmlHelper.locationNameWithChannelsAndIntegrations(locationOrLocationGroup);
        }
        return locationOrLocationGroup.displayName();
      },
    },
    liveFirst,
    maxSelectable,
  });

  return app.showModelSelector(
    {
      modalTitle,
      modelType: 'location',
      modelListView: locationListView,
      maxSelectable,
      allowEmpty,
    },
    callback,
  );
};
