import { BiteLogFatalError, BiteLogKioskEvent, BiteLogType } from '@biteinc/enums';
import { Time } from '@biteinc/helpers';

import { template } from '../../template';

app.BiteLogCellView = app.CellView.extend({
  className() {
    return `${app.CellView.prototype.className.apply(this, arguments)} bite-log-cell clickable`;
  },

  template: template(`
    <div class="cell-row main-content">
      <div class="timestamp"><@= timestamp @></div>
      <div class="device-name"><@= deviceName @></div>
      <div class="error-type"><@= eventType @></div>
      <div class="status"><@= status @></div>
    </div>
  `),

  render() {
    const biteLog = this.model;
    const type = biteLog.get('type');
    let eventTypeStr = biteLog.get('humanReadableType');
    let statusStr = biteLog.get('humanReadableStatus');
    if (BiteLogType.FatalError === biteLog.get('type')) {
      if (biteLog.get('status') === BiteLogFatalError.None) {
        eventTypeStr = 'Kiosk OK';
        statusStr = '';
      } else {
        eventTypeStr = 'Kiosk Down';
      }
    } else if (BiteLogType.Kiosk === type) {
      switch (biteLog.get('status')) {
        case BiteLogKioskEvent.StatusRecovered:
          eventTypeStr = 'Back Online';
          statusStr = '';
          break;
        case BiteLogKioskEvent.StatusMissing:
          eventTypeStr = 'Status Lost';
          statusStr = '';
          break;
      }
    }

    let deviceName = biteLog.get('clientName');
    if (type === BiteLogType.Kiosk && biteLog.get('kioskName')) {
      deviceName = biteLog.get('kioskName');
      statusStr += ` (${biteLog.get('clientName')})`;
    }

    const location =
      app.location ||
      (biteLog.get('locationId') && app.locationList?.get(biteLog.get('locationId')));
    const timezone = location?.get('timezone') || Time.BITE_TZ;
    this.$el.html(
      this.template({
        timestamp: Time.stringFromTimestamp(biteLog.get('createdAt'), timezone, true),
        deviceName,
        eventType: eventTypeStr,
        status: statusStr,
      }),
    );

    if (biteLog.get('kioskId')) {
      const kiosk = app.kioskList.get(biteLog.get('kioskId'));
      if (kiosk) {
        this.$('.main-content .device-name').text(kiosk.displayName());
      }
    }

    if (
      (type === BiteLogType.FatalError && biteLog.get('status') === BiteLogFatalError.None) ||
      (type === BiteLogType.Kiosk && biteLog.get('status') === BiteLogKioskEvent.StatusRecovered)
    ) {
      this.$el.addClass('kiosk-ok');
    } else if (biteLog.isError()) {
      this.$el.addClass('error');
    } else if (biteLog.isWarning()) {
      this.$el.addClass('warning');
    }

    this.attachListeners();

    return this;
  },
});
